import React, { useMemo } from 'react';
import { Box, Typography } from '@mui/material';

import Header from './components/Header';
import ControlButtons from './components/ControlButtons';
import RoundResults from './components/RoundResults';
import Footer from '../common/Footer';
import NotEnoughtModal from '../common/NotEnoughtModal';
import IntroModal from '../common/IntroModal';
import Scene from './components/Scene';
import MainLeaderboard from './components/MainLeaderboard';
import MainFortuneWheel from './components/MainFortuneWheel';
import AutoPlayModal from './components/AutoPlayModal';
import { useConnection } from '../../providers/ConnectionProvider/ConnectionProvider.hooks';
import { useNavigatorOnLine } from '../../hooks/useNavigatorOnLine';

function Main() {
  const { isWsConnectionReady } = useConnection();
  const onLineStatus = useNavigatorOnLine();

  const blockUI = useMemo(() => {
    return !(isWsConnectionReady && onLineStatus);
  }, [isWsConnectionReady, onLineStatus]);

  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: '#111015',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          maxWidth: '700px',
          width: '100%',
          margin: 'auto',
          position: 'relative',
        }}
      >
        <Header></Header>
        <Box
          sx={{
            position: 'relative',
          }}
        >
          {blockUI && (
            <Box
              sx={{
                display: 'flex',
                height: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'absolute',
                width: '100%',
              }}
            >
              <div className="loader"></div>
              <Typography
                sx={{
                  fontFamily: 'Avenir500',
                  fontSize: '10px',
                  lineHeight: 'normal',
                  color: '#fff',
                  position: 'absolute',
                }}
              >
                Connecting...
              </Typography>
            </Box>
          )}
          <Box
            sx={{
              opacity: blockUI ? 0.2 : 1,
              pointerEvents: blockUI ? 'none' : undefined,
            }}
          >
            <Scene></Scene>
            <ControlButtons></ControlButtons>
          </Box>
        </Box>
        <RoundResults />
        <MainLeaderboard />
        <MainFortuneWheel></MainFortuneWheel>
        <Footer></Footer>

        <AutoPlayModal></AutoPlayModal>
        <NotEnoughtModal></NotEnoughtModal>
        <IntroModal></IntroModal>
      </Box>
    </Box>
  );
}

export default Main;
