import React from 'react';
import { Box, Button, Fade, Modal, Typography } from '@mui/material';
import { useGameState } from '../../../providers/GameProvider/GameProvider.hooks';
import background from './assets/back.webp';
import { APP_VIEWS } from '../../../utils/constants';

function IntroModal() {
  const { isIntroOpen, setIntroOpen, setAppView } = useGameState();

  return (
    <Modal
      open={isIntroOpen}
      sx={{
        outline: 0,
        '& .MuiBackdrop-root': {
          background: 'rgba(17, 16, 21, 0.9)',
          outline: 0,
        },
      }}
    >
      <Fade in={isIntroOpen}>
        <Box
          sx={{
            height: '100%',
            outline: 0,
            maxWidth: '600px',
            width: '100%',
          }}
        >
          <Box
            sx={{
              height: '100%',
              padding: '20px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                borderRadius: '24px',
                overflow: 'hidden',
                background: '#24232A',
              }}
            >
              <img
                style={{
                  maxWidth: '100%',
                }}
                src={background}
                alt="background"
              ></img>
              <Box
                sx={{
                  padding: '32px 24px 24px',
                  display: 'flex',
                  gap: '16px',
                  flexDirection: 'column',
                }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Avenir600',
                    fontSize: '20px',
                    lineHeight: 'normal',
                    color: '#fff',
                    textAlign: 'center',
                  }}
                >
                  Welcome to Jetlix, Sky Captain!
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'Avenir400',
                    fontSize: '16px',
                    lineHeight: 'normal',
                    color: '#fff',
                    opacity: 0.5,
                    textAlign: 'center',
                  }}
                >
                  Ready for takeoff? Let’s make the sky your playground!
                </Typography>

                <Typography
                  sx={{
                    fontFamily: 'Avenir400',
                    fontSize: '16px',
                    lineHeight: 'normal',
                    color: 'rgba(255, 255, 255, 0.50)',
                    textAlign: 'center',
                    '& .link': {
                      textDecoration: 'none',
                      color: '#007AFF',
                      cursor: 'pointer',
                    },
                  }}
                >
                  Please read our{' '}
                  <span
                    className="link"
                    onClick={() => {
                      setAppView(APP_VIEWS.TERMS_OF_USE);
                    }}
                  >
                    terms of use
                  </span>{' '}
                  to get started. By clicking the button below, you confirm that you are over 18 years old and agree to
                  the terms!
                </Typography>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    fontFamily: 'Avenir600',
                    fontSize: '16px',
                    borderRadius: '12px',
                    background: '#007AFF',
                    height: '54px',
                    textTransform: 'none',
                    marginTop: '8px',
                    '&:hover': {
                      background: '#007AFF',
                    },
                  }}
                  onClick={() => {
                    localStorage.setItem('isIntroConfirmed', 'true');
                    setIntroOpen(false);
                  }}
                >
                  Continue
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}

export default IntroModal;
