import React from 'react';
import { Button } from '@mui/material';
import { ReactComponent as TonBtn } from './assets/ton_btn.svg';
import { useGameState } from '../../../../providers/GameProvider/GameProvider.hooks';
import { useConnection } from '../../../../providers/ConnectionProvider/ConnectionProvider.hooks';
import { PlaceBetState } from '../../../../providers/ConnectionProvider/ConnectionProvider.types';
import { truncNumber } from '../../../../utils/numbers';

function PlaceBetButton() {
  const { placeBetState, roundCashOutValue, action, userBalance } = useConnection();
  const { selectedBet, setNotEnoughtOpen } = useGameState();

  const handleClick = () => {
    if (
      (userBalance === null || userBalance <= 0 || userBalance < selectedBet) &&
      placeBetState !== PlaceBetState.CASH_OUT
    ) {
      setNotEnoughtOpen(true);
    } else {
      action(selectedBet);
    }
  };

  const renderButton = () => {
    const btnStyles = {
      height: '54px',
      width: '100%',
      fontFamily: 'Avenir600',
      fontSize: '16px',
      borderRadius: '12px',
      background: '#007AFF',
      textTransform: 'none',
      color: 'white',
      '&:hover': {
        backgroundColor: '#007AFF',
      },
    };

    switch (placeBetState) {
      case PlaceBetState.PLACE_BET: {
        return (
          <Button
            onClick={handleClick}
            sx={{
              ...btnStyles,
            }}
          >
            Place bet
          </Button>
        );
      }

      case PlaceBetState.CANCEL_BET: {
        return (
          <Button
            onClick={handleClick}
            sx={{
              ...btnStyles,
              background: '#F14135',
              '&:hover': {
                backgroundColor: '#F14135',
              },
            }}
          >
            Cancel bet
          </Button>
        );
      }
      case PlaceBetState.CASH_OUT: {
        return (
          <Button
            onClick={handleClick}
            sx={{
              ...btnStyles,
              background: '#84BE39',
              '&:hover': {
                backgroundColor: '#84BE39',
              },
              display: 'flex',
              gap: '12px',
            }}
          >
            Cash out
            <span
              style={{
                borderRadius: '20px',
                background: 'white',
                height: '32px',
                color: '#111015',
                padding: '0px 12px 0px 4px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '6px',
              }}
            >
              <TonBtn />
              {roundCashOutValue && truncNumber(roundCashOutValue)}
            </span>
          </Button>
        );
      }
    }
  };
  return renderButton();
}

export default PlaceBetButton;
