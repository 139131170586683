import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { keyframes } from '@emotion/react';
import Lottie from 'react-lottie-player';

import { useConnection } from '../../../../providers/ConnectionProvider/ConnectionProvider.hooks';
import * as rocketAnimationData from '../../../../assets/json/JSON_Rocket_1.json';
import * as rocketSecondAnimationData from '../../../../assets/json/JSON_Rocket_2.json';
import * as platformAnimationData from '../../../../assets/json/JSON_Platform_430_2.json';
import * as arrowsAnimationData from '../../../../assets/json/JSON_Arrows.json';
import gradient_sky from './assets/gradient_sky.webp';
import space_loop from './assets/space_loop.webp';
import Explode from './components/Explode';
import Stars from './components/Stars';
import { truncNumber } from '../../../../utils/numbers';

function Scene() {
  const { gameData } = useConnection();
  const [playAnimation, setPlayAnimation] = useState(false);
  const [isFirstAnimationPhaseCompleted, setFirstAnimationPhaseCompleted] = useState(false);
  const [skipFirstPhase, setSkipFirstPhase] = useState(false);
  const [startBgAnimation, setStartBgAnimation] = useState(false);
  const [showSpaceBg, setShowSpaceBg] = useState(false);

  useEffect(() => {
    if (gameData) {
      if (gameData.x > 0) {
        setPlayAnimation(true);
        setSkipFirstPhase(true);
        setFirstAnimationPhaseCompleted(true);
        setShowSpaceBg(true);
        setStartBgAnimation(true);
      } else {
        setSkipFirstPhase(false);
        setPlayAnimation(false);
        setFirstAnimationPhaseCompleted(false);
        setStartBgAnimation(false);
        if (gameData.x > -5) {
          setShowSpaceBg(false);
          setPlayAnimation(true);
          setStartBgAnimation(true);
        } else {
          setTimeout(() => {
            setShowSpaceBg(false);
            setPlayAnimation(true);
            setStartBgAnimation(true);
          }, 3400);
        }
      }
    }
  }, [gameData?.round_id]);

  const mainBgKeyframes = keyframes`
    0% {
      background-position: center 100%;
    }
    21.7% {
      background-position: center 100%;
    }
    100% {
      background-position: center 0%;
    }
`;

  const spaceBgKeyframes = keyframes`
  0% {
  background-position: center 0%;
  }
  100% {
  background-position: center -4000%;
  }
`;

  return (
    <Box
      onAnimationEnd={() => {
        setShowSpaceBg(true);
      }}
      sx={{
        width: '100%',
        height: '347px',
        backgroundImage: showSpaceBg ? `url(${space_loop})` : `url(${gradient_sky})`,
        backgroundRepeat: showSpaceBg ? 'repeat' : 'no-repeat',
        backgroundSize: 'cover',
        position: 'relative',
        willChange: 'background-position',
        animation: startBgAnimation
          ? showSpaceBg
            ? `${spaceBgKeyframes} 100s linear infinite`
            : `${mainBgKeyframes} 20s linear forwards`
          : undefined,
      }}
    >
      {startBgAnimation && showSpaceBg && <Stars></Stars>}
      {playAnimation ? (
        <>
          {!skipFirstPhase && (
            <>
              <Lottie
                play={playAnimation}
                loop={0}
                animationData={rocketAnimationData}
                style={{
                  width: '100px',
                  height: '287px',
                  position: 'absolute',
                  bottom: 0,
                  left: '50%',
                  transform: 'translateX(-50%)',
                  display: isFirstAnimationPhaseCompleted ? 'none' : undefined,
                }}
                onComplete={() => {
                  setFirstAnimationPhaseCompleted(true);
                }}
              />
              <Lottie
                play={playAnimation}
                loop={0}
                animationData={platformAnimationData}
                style={{
                  width: '430px',
                  height: '287px',
                  position: 'absolute',
                  bottom: 0,
                  left: '50%',
                  transform: 'translateX(-50%)',
                }}
              />
            </>
          )}

          <Lottie
            play={isFirstAnimationPhaseCompleted}
            animationData={rocketSecondAnimationData}
            style={{
              width: '100px',
              height: '287px',
              position: 'absolute',
              bottom: 0,
              left: '50%',
              transform: 'translateX(-50%)',
              display: isFirstAnimationPhaseCompleted ? undefined : 'none',
            }}
          />

          <Lottie
            play={isFirstAnimationPhaseCompleted}
            animationData={arrowsAnimationData}
            style={{
              width: '393px',
              height: '87px',
              position: 'absolute',
              bottom: 0,
              left: '50%',
              transform: 'translateX(-50%)',
              display: isFirstAnimationPhaseCompleted ? undefined : 'none',
            }}
          />
        </>
      ) : (
        <>
          {gameData ? (
            <Explode />
          ) : (
            <Box
              sx={{
                display: 'flex',
                height: '100%',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div className="loader"></div>
            </Box>
          )}
        </>
      )}

      {gameData && (
        <Box
          sx={{
            position: 'absolute',
            bottom: '40px',
            left: '50%',
            transform: 'translateX(-50%)',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Avenir700',
              fontSize: '40px',
              lineHeight: 'normal',
              color: '#fff',
            }}
          >
            {gameData.x > 0 && `${gameData.x.toFixed(2)}x`}
            {gameData.x < 0 && !playAnimation && `${truncNumber(gameData.rounds[0].x)}x`}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default Scene;
