import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { APP_VIEWS } from '../../utils/constants';
import { useGameState } from '../../providers/GameProvider/GameProvider.hooks';
import { useTelegram } from '../../providers/TelegramProvider/TelegramProvider';

function PrivacyPolicy() {
  const { setAppView } = useGameState();
  const { webApp } = useTelegram();
  useEffect(() => {
    if (webApp) {
      webApp.BackButton.onClick(() => {
        webApp.BackButton.hide();
        setAppView(APP_VIEWS.MAIN);
      });
      webApp.BackButton.show();
    }
  }, [webApp, setAppView]);
  return (
    <Box
      sx={{
        maxWidth: '700px',
        margin: 'auto',
        overflow: 'hidden',
        position: 'relative',
        backgroundColor: 'white',
        padding: '20px',
      }}
    >
      <Box
        sx={
          {
            // color: 'white'
          }
        }
      >
        <p>
          <strong>Privacy policy of&nbsp;</strong>
          <strong>Jetlix</strong>
          <strong>Web app</strong>
        </p>
        <p>
          <strong>Date: February 21, 2024</strong>
        </p>
        <p>
          We ROSIANE DA ROSA ANDRADES (hereinafter &ldquo;we&rdquo;, &ldquo;us&rdquo; etc.), a citizen of the Brazil,
          whose residence address is at Rua Nove 1424, Jaboatão Dos Guararapes, Pernambuco 54240-080, Brazil, want to
          preserve rights of our Users about their personal data during and after the use of our Website, Web
          application (collectively referred as &ldquo;Service&rdquo;). This Privacy Policy explains what personal data
          is collected when you use the Jetlix website located at: https://jetlix.com/ (the &ldquo;Website&rdquo; or
          &ldquo;App&rdquo;), the services and products provided through it (together with the Website, the
          &ldquo;Service&rdquo;), how such personal data will be used, shared.
        </p>
        <p>
          When you access our App we automatically collect from your device language settings, IP address, time zone,
          type and model of a device, device settings, operating system, Internet service provider, mobile carrier,
          hardware ID, and other unique identifiers. We need this data to provide our services, analyze how our
          customers use the App and to serve ads.
        </p>
        <p>
          We process information that is collected by cookies, log files, clear gifs, and/or by third parties' support
          in order to create player profiles. A profile contains information on individual players including preferences
          concerning the activities of the player. This information is related to the players' personal and identifiable
          data and used to provide and improve our services to the benefit of the player. Profiles are also used to
          design each player's experience on the Website and App individually and to provide specific marketing
          information.
        </p>
        <p>
          For improving the App and serving ads, we may share this data with third parties. As a result of sharing this
          data with third parties we (1) analyze different interactions (what products our users have viewed); (2) serve
          ads (and show them only to a particular group of users, for example, to those who have added a product to a
          cart).
        </p>
        <p>
          Please note that Jetlix doesn&rsquo;t have any hidden features or functionalities as well as it doesn&rsquo;t
          have a real money gambling functionality. This is a first version of popular style game that will be monetized
          through ad networks after initial launch.
        </p>
        <p>
          We tried to shorten this privacy policy, so it would be easier to understand the rights of a User (referred as
          &ldquo;you&rdquo;, &ldquo;yours&rdquo; etc.) and obligations of the ROSIANE DA ROSA ANDRADES about Users
          personal data.
        </p>
        <p>
          Where applicable, if you do not provide us the relevant Personal Data, you may not be able to use our Services
          completely. You should not use the Services if you do not agree with this Policy, our{' '}
          <a href="terms.html">Terms of Use</a> and any other agreement that governs your use of the Services.
        </p>
        <p>
          BY USING THE SERVICE, YOU PROMISE US THAT (I) YOU HAVE READ, UNDERSTAND AND AGREE TO THIS PRIVACY POLICY, AND
          (II) YOU ARE OVER 17 YEARS OF AGE (OR HAVE HAD YOUR PARENT OR GUARDIAN READ AND AGREE TO THIS PRIVACY POLICY
          FOR YOU). If you do not agree, or are unable to make this promise, you must not use the Service. In such a
          case, you must (a) contact us and request deletion of your data; and (b) leave the Service and not access or
          use it.
        </p>
        <p>
          If any questions remain unanswered, please contact us at{' '}
          <a href="mailto:support%40jetlix.com">support@jetlix.com</a>.
        </p>
        <p>
          <strong>Privacy Policy Consent</strong>
        </p>
        <p>
          By using the Jetlix and its affiliated services, you consent to the Privacy Policy and its information
          administration practices. We may provide additional disclosures in relation to the privacy policy. These
          documents will apply supplementary to Jetlix&rsquo;s Privacy Policy. These disclosures may expand on
          information administration practices or provide further clarification for specific practices.
        </p>
        <p>
          If you disagree with or are not comfortable with any aspect of Jetlix&rsquo;s Privacy Policy, you should
          immediately cease the use of Jetlix&rsquo;s services.
        </p>
        <p>
          <strong>Changes to the Privacy Policy</strong>
        </p>
        <p>
          Jetlix reserves the right to alter and/or modify this privacy policy at any time. Please review this policy
          periodically in case of any changes.
        </p>
        <p>
          We will alert you to material changes by placing the Notice on our Website/App. You can see for yourself when
          this Notice was last updated by checking the date at the top of this page.
        </p>
        <p>
          <strong>Who Does Jetlix&rsquo;s Privacy Policy Apply To?</strong>
        </p>
        <p>
          Jetlix&rsquo;s Privacy Policy applies to users of App&rsquo;s services, including individuals, merchants, and
          legal entities.
        </p>
        <p>Jetlix&rsquo;s users are those who use and/or purchase Jetlix Website, Web app or affiliated services.</p>
        <p>
          <strong>What Personal Information Do We Collect?</strong>
        </p>
        <p>ROSIANE DA ROSA ANDRADES only collects information that is vital to the provision of our services.</p>
        <p>The information that we may collect includes, but is not limited to:</p>
        <ol>
          <li>
            <p>
              <strong>Identification Information:</strong>
            </p>
            <ul>
              <li>
                User ID (used for Product Personalization, Analytics, and App Functionality; linked to the user's
                identity);
              </li>
              <li>
                Device ID (used for Analytics, and App Functionality linked to the user's identity used for tracking
                purposes);
              </li>
            </ul>
          </li>
          <li>
            <p>
              <strong>Usage Data</strong>
              <strong>:&nbsp;</strong>
            </p>
            <ul>
              <li>Product Interaction (used for Analytics, Product Personalization, and App Functionality);</li>
              <li>Advertising Data (used for Product Personalization, App Functionality, and Analytics);</li>
            </ul>
          </li>
          <li>
            <p>
              <strong>Diagnostic Information:&nbsp;</strong>
            </p>
            <ul>
              <li>Crash Data (used for Analytics, and App Functionality);</li>
              <li>Other Diagnostic Data (used for App Functionality, and Analytics).</li>
            </ul>
          </li>
        </ol>
        <p>
          <strong>Why Do We Collect This Information?</strong>
        </p>
        <ol>
          <li>
            <strong>To Create a Better Experience for Our Clients</strong>
            <p>
              We collect your personal data to ensure the Service providing, to analyze your behavior during the usage
              of our Service, to add new features to our Service and to provide our third-party service providers with
              information, crucial for their services.
            </p>
            <p>
              Providing Service includes enabling you to use the Service in a seamless manner and preventing or
              addressing Service errors or technical issues. As a result of this processing, we will use your e-mail,
              for example, to register in the application.
            </p>
            <p>
              Providing Service includes enabling you to use the Service in a seamless manner and preventing or
              addressing Service errors or technical issues. As a result of such processing, we will use your email, for
              example, to send you the purchased product or to render services ordered through our App. To enable the
              purchase and to process your payments we could use Stripe and Recurly, payment processing providers.
            </p>
          </li>
          <li>
            <strong>To Protect Our Clients&nbsp;</strong>
            <p>
              We collect your personal data to ensure the Service providing, to analyze your behavior during the usage
              of our Service, to add new features to our Service and to provide our third-party service providers with
              information, crucial for their services.
            </p>
            <p>
              Providing Service includes enabling you to use the Service in a seamless manner and preventing or
              addressing Service errors or technical issues.
            </p>
          </li>
          <li>
            <strong>To Comply with Regulatory Requirements</strong>
            <p>
              The services offered by ROSIANE DA ROSA ANDRADES are subject to international regulations which require us
              to collect your personal information.
            </p>
          </li>
          <li>
            <strong>To Enforce our Terms in Our User Agreement</strong>
            <p>
              It is important for ROSIANE DA ROSA ANDRADES to regulate its services and its customers relating to the
              prevention and mitigation of any potentially prohibited activities, enforcing our agreements with third
              parties, and violations of our user agreement. For these purposes, it is essential for ROSIANE DA ROSA
              ANDRADES to collect user data.
            </p>
            <p>
              If ROSIANE DA ROSA ANDRADES is unable to process user information for these reasons, the consequence is
              the termination of your account as we cannot perform our services in accordance with our terms.
            </p>
          </li>
          <li>
            <strong>Maintain your Registration as a Client</strong>
            <p>This will allow you to access your account safely and securely whenever and wherever you wish.</p>
          </li>
          <li>
            <strong>Ensure Quality Control</strong>
            <p>
              We store user information for quality control and staff training, to ensure we provide users with accurate
              information.
            </p>
          </li>
          <li>
            <strong>Marketing Activities</strong>
            <p>
              We may send you marketing or promotional offers to inform you of Jetlix or affiliates&rsquo; events, to
              deliver targeted marketing, and to provide you with promotional offers based on your communication
              preferences. We use information relating to your usage of Jetlix and contact information to provide a
              better marketing experience.
            </p>
          </li>
        </ol>
        <p>
          <strong>How do we use your data?</strong>
        </p>
        <p>We collect, store, process, use, analyze and share (in some situations) your personal data.</p>
        <p>
          We may send one or more small data files called - &ldquo;cookies&rdquo; - to your computer to uniquely
          identify your browser and let us help you log in faster and enhance your navigation through the Site.
        </p>
        <p>
          Cookies may convey anonymous information about how you browse the Services to us so we can provide you with a
          more personalized experience, but do not collect personal information about you.
        </p>
        <p>We may use different types of cookies:</p>
        <p>
          - Essential Cookies (to provide you with Services available through our Site and to enable you to use some of
          its features),
        </p>
        <p>
          - Functionality Cookies (to remember your login details and changes you make, this help us to make Service
          personal oriented),
        </p>
        <p>
          - Analytics Cookies (this cookies help us to collect information about traffic to our Site and how users use
          our Site, but this information doesn&rsquo;t include personal identify information about user, the information
          collected is aggregated and anonymous),
        </p>
        <p>
          - Social Media Cookies (these cookies are used when you share information using social media sharing). We may
          use web and mobile analytics and advertising services and technologies (such as Google Analytics, Facebook
          Analytics, Facebook Pixel, Firebase, AppStore Analytics, Tenjin, GameAnalytics, AdMob etc.) to assist in
          collecting certain information about the devices or computer you use to access the Apps and Sites.
        </p>
        <p>
          Users can opt out of certain types of tracking by Google Analytics. If you do not want to participate in
          Google Analytics, you can download a browser add-on that blocks the display of ads. We may use remarketing
          technology like Google Remarketing. We may use Google Remarketing to serve targeted advertisements. You can
          disable this function by using the appropriate settings on the website http: //www.google.com/settings/ads.
        </p>
        <p>
          Further information about cookies, including how to see what cookies have been set on your computer or mobile
          device and how to manage and delete them, visit www.allaboutcookies.org and www.youronlinechoices.com.
        </p>
        <p>
          Aggregated or De-Identified Information. We may aggregate or de-identify Personal Information and use the
          aggregated information to analyze the effectiveness of our Services, to improve and add features to our
          Services, to conduct research and for other similar purposes. In addition, from time to time, we may analyze
          the general behavior and characteristics of users of our Services. We may collect aggregated information
          through the Services, through cookies, and through other means described in this Privacy Policy. We will
          maintain and use de-identified information in anonymous or de-identified form and we will not attempt to
          reidentify the information.
        </p>
        <p>
          <strong>Who Will We Share Your Information With?</strong>
        </p>
        <p>Information that Jetlix Application collects may or may not be used or disclosed to:</p>
        <ul>
          <li>I, ROSIANE DA ROSA ANDRADES;</li>
          <li>
            FirebaseRemoteConfig (Application functionality)
            <a href="https://firebase.google.com/docs/ios/app-store-data-collection%23firebaseremoteconfig">
              https://firebase.google.com/docs/ios/app-store-data-collection#firebaseremoteconfig
            </a>
            , which provided by Google LLC
            <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>;&nbsp;
          </li>
          <li>Google Inc. (analytics&nbsp;platform) https://policies.google.com/privacy;</li>
          <li>
            FacebookSDK (analytics platform,
            <a href="https://opensource.fb.com/legal/privacy">https://opensource.fb.com/legal/privacy</a>);
          </li>
          <li>
            Individuals, entities, organisations, as part of Lucky Plane related services to clients and includes, but
            is not limited to, experts, tribunals, law firms, and other associated professionals;
          </li>
          <li>Persons the client has expressly authorised or consented;</li>
          <li>
            Jetlix&rsquo;s associated commercial and business partners, which include, but are not limited to,
            professional advisors, commercial organisations, and business agents;
          </li>
          <li>Comply with legal obligations or requirements;</li>
          <li>Develop business and commercial relationships;</li>
          <li>
            Law enforcement agencies and other public authorities. We may use and disclose personal data to enforce our
            <a href="terms.html">Terms and Conditions of Use</a>, to protect our rights, privacy, safety, or property,
            and/or that of our affiliates, you or others, and to respond to requests from courts, law enforcement
            agencies, regulatory agencies, and other public and government authorities, or in other cases provided for
            by law.
          </li>
          <li>
            Third parties as part of a merger or acquisition. As we develop our business, we may buy or sell assets or
            business offerings. Customers&rsquo; information is generally one of the transferred business assets in
            these types of transactions. We may also share such information with any affiliated entity (e.g. parent
            company or subsidiary) and may transfer such information in the course of a corporate transaction, such as
            the sale of our business, a divestiture, merger, consolidation, or asset sale, or in the unlikely event of
            bankruptcy.
          </li>
          <li>Respond to any enquiries where necessary; and</li>
          <li>
            All other matters, services, engagements, business developments, and commercial enterprises to the maximum
            extent permitted by law.
          </li>
        </ul>
        <p>Data retention periods for other Processors (other than Us) are indicated by the links above.</p>
        <p>
          <strong>How Is Your Information Protected?</strong>
        </p>
        <p>
          ROSIANE DA ROSA ANDRADES considers our user privacy to be of great importance. That is why we have implemented
          multiple security measures to ensure that client data is secure, free from unauthorised access, alteration,
          disclosure, and destruction. For example, we implement the following security measures:
        </p>
        <ul>
          <li>Security procedures within our offices;</li>
          <li>IT security procedures including password protection, site monitoring, firewalls; and</li>
          <li>Mandatory confidentiality guidements for all staff within the business.</li>
        </ul>
        <p>
          However, despite the measures we have in place, data protection is never completely secure. We cannot
          guarantee the security of your personal information.
        </p>
        <p>
          ROSIANE DA ROSA ANDRADES cannot guarantee that loss, misuse, unauthorised acquisition, or alteration of your
          data will not occur. Please note that the client plays a critical role in ensuring their own personal
          information.
        </p>
        <p>
          Furthermore, we cannot ensure or warrant the security or confidentiality of information you transmit to us or
          receive from us by Internet or wireless connection, including email, phone, or SMS, since we have no way of
          protecting that information once it leaves and until it reaches us. If you have reason to believe that your
          data is no longer secure, please contact us at the email address, mailing address or telephone number listed
          at the end of this Privacy Policy.
        </p>
        <p>
          <strong>Your consent. Term of storage of your personal data.</strong>
        </p>
        <p>
          The User hereby freely gives, specific, informed and unambiguous consent to the processing of the personal
          data relating to the User and mentioned above for the purposes of personal data processing indicated above.
        </p>
        <p>
          For the purposes of analytics and advertising, we use services and solutions provided by third parties. Such
          parties process data on our behalf and the respective technical solutions represent a part of the Services.
          Any transfer of your Personal Data is based on a written agreement, and we make sure such a third party
          complies with the applicable data protection regulations. Such third parties process Personal Data in
          accordance with their own privacy policies. Please, check privacy policies of such third parties.
        </p>
        <p>
          In certain cases we may transfer information about you to other third parties (for example, independent
          personal data controllers), if we are obliged or allowed to do so by applicable laws. If it is the case, we
          transfer such information while adhering to applicable personal data protection laws.
        </p>
        <p>
          If ROSIANE DA ROSA ANDRADES is involved, whether in whole or in part, in a merger, sale, acquisition,
          divestiture, restructuring, reorganization, dissolution, bankruptcy or other change of ownership or control,
          we may transfer Personal Data, together with business assets, to another company. In this case the respective
          notification will be available on the web-page of the Privacy Policy.
        </p>
        <p>We also may transfer your Personal Data to a third party in the following cases:</p>
        <ul>
          <li>
            if we need so to protect legal rights of ROSIANE DA ROSA ANDRADES, our end users, business parties or other
            interested parties;
          </li>
          <li>if we need so to enforce our agreements with you;</li>
          <li>
            if we need so to investigate, prevent, or take other action regarding illegal activity, suspected fraud or
            other wrongdoing.
          </li>
        </ul>
        <p>
          If you are located outside of the USA and choose to provide information to us, please note that we may
          transfer the data, including Personal Data, to the USA and process it there. Your consent to this Privacy
          Policy followed by your submission of such information represents your agreement to that transfer.
        </p>
        <p>
          We retain all Personal Data for as long as you use the Services and 24 months after you stop using the
          Services. If you do not use the Services for 24 continuous months, we erase your Personal Data from our
          database, as well as request the same erasure from any third party, whom your Personal Data may have been
          transferred to. We may erase your Personal Data earlier if we do not need to process such data anymore.
        </p>
        <p>
          <strong>Children&rsquo;s data</strong>
        </p>
        <p>
          The Jetlix is intended for users who are at least 16 years old. People under the age of 17 are not permitted
          to use the Jetlix Application without permission and oversight by a legal guardian over the age of 18 years.
        </p>
        <p>
          In accordance with the Federal Children&rsquo;s Online Privacy Protection Act of 1998 (&ldquo;COPPA&rdquo;),
          we will never knowingly solicit, nor will we accept, personally identifiable information from users of the
          Service known to be under 16 years of age. Please see our privacy policy for additional information, including
          information regarding how to notify us of any concerns you may have in this regard.
        </p>
        <p>
          If you believe that we have information from or about anyone under seventeen (17) years of age, please notify
          us by email at <a href="mailto:support%40jetlix.com">support@jetlix.com</a> for fastest processing, please
          include the phrase &ldquo;COPPA Concern&rdquo; in the subject line and in the body of your message.
        </p>
        <p>
          <strong>Legal Bases</strong>
        </p>
        <p>
          Every time we collect and process your information, we do so in accordance with on of the following legal
          basis:
        </p>
        <p>
          &bull; <strong>Contractual Necessity</strong>&mdash;for data we use to provide you with Services you have
          requested;
        </p>
        <p>
          &bull; <strong>Legal Obligation</strong>&mdash;for data we use to comply with our legal obligation or to
          respond to a lawful request for information;
        </p>
        <p>
          &bull; <strong>Legitimate Interest</strong>&mdash;for data we use for purposes that are not harmful to your
          privacy and can be reasonably expected from the context of your use of the Jetlix, such as analytics,
          research, marketing, advertising, and continuous improvement of the Jetlix; and
        </p>
        <p>
          &bull; <strong>Consent</strong>&mdash;you consent to collection of data when you start to use the Jetlix and
          you can withdraw your consent at any time.
        </p>
        <p>If you do not want us to collect any personal information about you, you should stop using the Jetlix.</p>
        <p>
          <strong>Obtaining Information Through Third Party Sources</strong>
        </p>
        <p>
          Jetlix will occasionally obtain information about you through third party. Information will only be obtained
          as required permitted by applicable law. Examples of this include public databases, credit bureaus, resellers
          and channel partners, and social media platforms.
        </p>
        <p>We also may transfer your Personal Data to a third party in the following cases:</p>
        <ul>
          <li>
            if we need so to protect legal rights of ROSIANE DA ROSA ANDRADES our end users, business parties or other
            interested parties;
          </li>
          <li>if we need so to enforce our agreements with you;</li>
          <li>
            if we need so to investigate, prevent, or take other action regarding illegal activity, suspected fraud or
            other wrongdoing.
          </li>
        </ul>
        <p>
          <strong>How We Transfer Your Information</strong>
        </p>
        <p>
          ROSIANE DA ROSA ANDRADES is based in the Brazil, and has operations, entities, and service providers in the
          Brazil and throughout the world. As such, we and our service providers may transfer your personal information
          to, or access it in, jurisdictions that may not provide equivalent levels of data protection as your home
          jurisdiction. We take steps to ensure that your personal information receives an adequate level of protection
          in the jurisdictions in which we process it. Where we transfer your personal information outside of the
          European Economic Area or the United Kingdom, we rely on transfer mechanisms such as the European-Commission
          approved Standard Contractual Clauses. For more information on our transfer mechanisms, please contact us at
          the details below.
        </p>
        <p>
          <strong>Control third-party web-sites</strong>
        </p>
        <p>
          This Privacy Policy may contain links to other web-sites that are not operated by us. If you click on such a
          link, you will be directed to that third party's site. We strongly recommend you to review the privacy policy
          and terms of use of every web-site you visit. Under no circumstances do we control third parties&rsquo;
          web-sites or assume any responsibility for the content, privacy policy, terms of use or practices of any
          third-party web-sites or services.
        </p>
        <p>
          <strong>Automatically Collected Data</strong>
        </p>
        <p>
          Jetlix receives and stores certain types of data whenever you interact with Jetlix services. Data collected
          automatically includes any category listed under personal usage information.
        </p>
        <p>
          <strong>Why We Share Personal Information with Other Parties</strong>
        </p>
        <p>
          ROSIANE DA ROSA ANDRADES will never sell or rent your personal information. We will only share your
          information in the following circumstances:
        </p>
        <ul>
          <li>
            We share your information with third party identity verification services to prevent fraud. Jetlix shares
            this information to confirm your identity with relevant public records to prevent fraud. These third-party
            services will store your information; however, they will only use this information for purposes of identity
            verification.
          </li>
          <li>
            Jetlix may share your information with affiliated service providers. These affiliated services providers may
            include debt collection service agents, marketing firms, and technology services.
          </li>
          <li>
            We will share your information with financial institutions which Jetlix has partnered with to process
            payments.
          </li>
          <li>
            We may share your information with companies or entities that we purchase or merge with. The new company or
            entity will uphold this Privacy Policy.
          </li>
          <li>
            We may share your information with law enforcement officials or other entities when required to do so under
            applicable law.
          </li>
          <li>
            We may share your information with law enforcement, officials, or other third parties when we are compelled
            to do so by a subpoena, court order, or similar legal procedure, or when we believe in good faith that the
            disclosure of personal information is necessary to prevent physical harm or financial loss, to report
            suspected illegal activity or to investigate violations of our User Agreement or any other applicable
            policies.
          </li>
          <li>
            We make also use of trusted third parties to process players&rsquo; personal data including but not limited
            to payment services, information technology, statistics and reporting, customer support, sales, marketing
            related services. Pursuant to applicable data protection regulations including the General Data Protection
            Regulation these third parties are contractually bound to us and obliged to treat players' data securely and
            confidentially. Their use of the said data is restricted to the extent required to fulfil the respective
            task.
          </li>
        </ul>
        <p>
          <strong>How We Protect and Store Personal Information</strong>
        </p>
        <p>
          Jetlix may hold sensitive information in our facilities or service providers located worldwide. We protect
          your personal information by maintaining physical, electronic, and procedural safeguards in compliance with
          the applicable laws and regulations.
        </p>
        <p>
          Jetlix cannot guarantee that loss, misuse, unauthorised acquisition, or alteration of your data will not
          occur. Please note that the client plays a critical role in ensuring their own personal information.
        </p>
        <p>
          Furthermore, we cannot ensure or warrant the security or confidentiality of information you transmit to us or
          receive from us by Internet or wireless connection, including email, phone, or SMS, since we have no way of
          protecting that information once it leaves and until it reaches us. If you have reason to believe that your
          data is no longer secure, please contact us at the email address, mailing address or telephone number listed
          at the end of this Privacy Policy.
        </p>
        <p>
          <strong>Your rights about your personal data</strong>
        </p>
        <p>
          You have the right of access, rectification, erasure of your personal data, restriction of processing of your
          personal data as well as the right to data portability. Furthermore, you have the option to file a complaint
          against the processing of your personal data with the competent supervisory authority.
        </p>
        <p>You have the right to access your Personal Data, which we process.</p>
        <p>
          You have the right of rectification (correction) of your Personal Data. You have the right to ask us to
          replace any inaccurate data about you with accurate data. If you think that the data we process are incomplete
          for the purpose of processing as stated in this Privacy Policy, you may ask us to complete your Personal Data
          accordingly.
        </p>
        <p>You have the right to ask us to completely delete your Personal Data.</p>
        <p>
          You have the right to restrict processing. Please, be informed that this right may be exercised only in a
          situation set forth in Article 18 of General Data Protection Regulation 2016/679.
        </p>
        <p>
          If you have given your consent to the processing of your data, you can revoke the given consent at any time.
          Such revocation affects the admissibility of the processing of your personal data after you make such
          revocation.
        </p>
        <p>
          You can object to the processing of your personal data insofar as we base the processing of your personal data
          on the balance of interests. This is the case in particular if the processing is not necessary for the
          fulfillment of a contractual obligation to you, which we in each case present in the description of the
          functions which follow. In case of such objection, we kindly ask for an explanation of the reasons for the
          objection against the processing of your personal data. In the case of your justified objection, we will
          examine the situation and will either discontinue or adapt the data processing or point out to you our
          compelling legitimate reasons based on which we continue processing your personal data.
        </p>
        <p>
          You may, of course, object to the processing of your personal data for data analysis purposes at any time.
        </p>
        <p>You may also request a copy of your personal data collected during your use of the App.</p>
        <p>
          In this case, please send an e-mail to <a href="mailto:support%40jetlix.com">support@jetlix.com</a>.
        </p>
        <p>
          If you are based in the EEA, you have the following rights in addition to the above: The right to lodge a
          complaint with supervisory authority. We would love you to contact us directly, so we could address your
          concerns. Nevertheless, you have the right to lodge a complaint with a competent data protection supervisory
          authority, in particular in the EU Member State where you reside, work or where the alleged infringement has
          taken place.
        </p>
        `
        <p>
          The right to data portability. If you wish to receive your personal data in a machine-readable format, you can
          send a respective request at <a href="mailto:support%40jetlix.com">support@jetlix.com</a>.
        </p>
        <p>
          <strong>How You Can Access or Change Personal Information</strong>
        </p>
        <p>
          You are entitled to review, correct, or amend your personal information, or to delete that information where
          it is inaccurate. You may do this by contacting us at support@skycrash.online.
        </p>
        <p>
          If your account is closed or terminated, Jetlix will keep your account information in our database for at
          least two years for regulatory purposes. This ensures that those who are attempting to avoid fraud cannot
          achieve this by simply closing or terminating their account. When your account is closed or terminated, your
          information will not be used by Jetlix for any further purposes, including being shared to third parties.
        </p>
        <p>
          <strong>General Data Protection Regulations (GDPR)</strong>
        </p>
        <p>
          The General Data Protection Regulations (GDPR) apply to organisations who process data in the EU as well as
          organisations that offer goods or services to or monitors the behaviour of the people inside the EU. The GDPR
          applies to all information that can directly or indirectly influence an individual.
        </p>
        <p>
          Jetlix has prepared itself for the General Data Protection Regulations by providing information as to when
          Jetlix data is deleted, have provided a transparent overview of Jetlix&rsquo;s use of the personal information
          it has collected, and detailed how you can exercise your privacy rights.
        </p>
        <p>European Union Residents have the following rights:</p>
        <ol>
          <li>
            You will have the <strong>right to withdraw your consent</strong> to process your personal
            information.&nbsp;
          </li>
          <li>
            You have the <strong>right to access your Personal Data</strong>, which we process.&nbsp;
          </li>
          <li>
            You have the <strong>right of</strong>
            <strong>rectification (correction) of your Personal Data</strong>. You have the right to ask us to replace
            any inaccurate data about you with accurate data. If you think that the data we process are incomplete for
            the purpose of processing as stated in this Privacy Policy, you may ask us to complete your Personal Data
            accordingly.
          </li>
          <li>You have the right to ask us to completely delete your Personal Data.</li>
          <li>
            You have a right to request Jetlix provide you with a <strong>copy of your personal information</strong>{' '}
            held by us. This information will be provided without undue delay subject to some fee associated with
            gathering of the information (as permitted by law), unless such provision adversely affects the rights and
            freedoms of others.
          </li>
          <li>
            You have the right to request Jetlix
            <strong>update any of your personal information</strong> that you believe incorrect or inaccurate.&nbsp;
          </li>
          <li>You may also request a copy of your personal data collected during your use of the Jetlix.</li>
          <li>
            <strong>Right to Erasure</strong>
            <p>You have the right to request erasure of your personal information that:&nbsp;</p>
            <ol>
              <li>
                is no longer necessary in relation to the purposes for which it was collected or otherwise processed;
              </li>
              <li>
                was collected in relation to processing that you previously consented, but later withdraw such consent;
                or
              </li>
              <li>
                was collected in relation to processing activities to which you object, and there are no overriding
                legitimate grounds for our processing.&nbsp;
              </li>
            </ol>
          </li>
          <li>
            <strong>Right to Data Portability</strong>
            <p>
              If we process your personal information based on a contract with you or based on your consent, or the
              processing is carried out by automated means, you may request to receive your personal information in a
              structured, commonly used and machine-readable format, and to have us transfer your personal information
              directly to another &ldquo;controller&rdquo;, where technically feasible, unless exercise of this right
              adversely affects the rights and freedoms of others.
            </p>
            <p>
              A &ldquo;controller&rdquo; Is a natural or legal person, public authority, agency, or other body which,
              alone or jointly with others, determines the purposes and means of the processing of your personal
              information.
            </p>
          </li>
          <li>
            <strong>Right to Restriction of or Processing</strong>
            <p>
              You have the right to restrict or object to us processing your personal information where one of the
              following applies:
            </p>
            <ol>
              <li>
                You contest the accuracy of your personal information that we processed. In such instances, we will
                restrict processing during the period necessary for us to verify the accuracy of your personal
                information.
              </li>
              <li>
                The processing is unlawful, and you oppose the erasure of your personal information and request the
                restriction of its use instead.
              </li>
              <li>
                We no longer need your personal information for the purposes of the processing, but it is required by
                you to establish, exercise or defence of legal claims.
              </li>
              <li>You have objected to processing, pending the verification.</li>
            </ol>
          </li>
          <li>
            <strong>Restricted Personal Information</strong> shall only be processed with your consent or for the
            establishment, exercise, or defence of legal claims or for the protection of the rights of another natural
            or legal person or for reasons of important public interest. We will inform you if the restriction is
            lifted.
          </li>
          <li>
            <strong>Notification of Erasure, Rectification and Restriction</strong>
            <p>
              Jetlix will communicate any rectification or erasure of your personal information or restriction of
              processing to each recipient to whom your personal information has been disclosed, unless this proves
              impossible or involves disproportionate effort. We will inform you about those recipients if you request
              this information.
            </p>
          </li>
          <li>
            <strong>Right to Object to Processing</strong>
            <p>
              Where the processing of your personal information is based on consent, contract, or legitimate interests
              you may restrict or object, at any time, to the processing of your personal information as permitted by
              applicable law. We can continue to process your personal information if it is necessary for the defence of
              legal claims, or for any other exceptions permitted by applicable law.
            </p>
          </li>
          <li>
            <strong>Automated individual decision-making and profiling</strong>
            <p>
              You have the right not to be subject to a decision based solely on automated processing of your personal
              information, including profiling, which produces legal or similarly significant effects on you, save for
              the exceptions applicable under relevant data protection laws.
            </p>
          </li>
          <li>
            <p>
              If you are based in the EEA, you have the following rights in addition to the above: The{' '}
              <strong>right to lodge a complaint with supervisory authority</strong>. We would love you to contact us
              directly, so we could address your concerns. Nevertheless, you have the right to lodge a complaint with a
              competent data protection supervisory authority, in particular in the EU Member State where you reside,
              work or where the alleged infringement has taken place.
            </p>
            <p>
              If you believe Jetlix has infringed your rights, we kindly request that you contact prior to lodging a
              complaint with the relevant authorities so that we may attempt to resolve the dispute. Please contact us
              at <a href="mailto:support%40jetlix.com">support@jetlix.com</a>.
            </p>
          </li>
        </ol>
        <p>
          <strong>CONTACT US</strong>
        </p>
        <p>
          If you have an enquiry regarding our Privacy Policy surrounding personal information, you can contact us at{' '}
          <a href="mailto:support%40jetlix.com">support@jetlix.com</a>.&nbsp;
        </p>
        <p>ROSIANE DA ROSA ANDRADES</p>
        <p>Rua Nove 1424, Jaboatão Dos Guararapes, Pernambuco 54240-080, Brazil</p>
        <p>
          Email: <a href="mailto:support%40jetlix.com">support@jetlix.com</a>
        </p>
      </Box>
    </Box>
  );
}

export default PrivacyPolicy;
