import { MutableRefObject } from 'react';
import { AutoPlayValuesType } from '../../utils/constants';

export interface IConnectionContext {
  gameData: GameDataType | null;
  placeBetState: PlaceBetState;
  roundCashOutValue: number | null;
  action: (betValue: number, autoTakeVal?: number) => void;
  ws: MutableRefObject<WebSocket | null>;
  roundResults: ShortRoundResult[];
  userBalance: number | null;
  isUserNotFound: boolean;
  userNetwork: string | null;
  isWsConnectionReady: boolean;
  autoPlayData: AutoPlayValuesType | null;
  setAutoPlayData: React.Dispatch<React.SetStateAction<AutoPlayValuesType | null>>;
}

export type GameDataType = {
  x: number;
  round_id: number;
  rtp: number;
  balance: number;
  next_round_bets: Record<string, RoundBetsType>;
  round_bets: Record<string, RoundBetsType>;
  elapsed_time: number;
  rounds: Array<{
    id: number;
    x: number;
  }>;
};

type RoundBetsType = Record<
  string,
  {
    id: number;
    round_id: number;
    uid: string;
    take_x: null | number;
    is_auto: boolean;
    is_taken: boolean;
    is_calculated: boolean;
    bet_val: number;
    result: null | number;
    result_plus_bet: null | number;
    bet_num: number;
    game: string;
    is_balance_confirmed: boolean;
    is_bet_transacted: null | boolean;
    is_win_transacted: null | boolean;
  }
>;

export type PlaceBetType = {
  jwt: string;
  game: string;
  action: string;
  bet_num: number;
  auto_take: boolean;
  auto_take_val: number;
  bet_val: number;
};

export enum PlaceBetState {
  PLACE_BET = 'place_bet',
  CANCEL_BET = 'cancel_bet',
  CASH_OUT = 'cash_out',
}

export type RegisteredBet = {
  id: number;
  round_id: number;
  bet_val: number;
};

export type ShortRoundResult = {
  round_id: number;
  bet_val: number;
};
