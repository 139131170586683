import { Box, Button, Typography } from '@mui/material';
import { COLORS, USERS_MOCK } from '../../../utils/mock';
import { ReactComponent as UpIcon } from './assets/up_icon.svg';
import { ReactComponent as TonCoin } from './assets/ton_coin.svg';

function LeaderboardTable({
  tables,
  selectedTable,
  setSelectedTable,
  tableData,
}: {
  tables: string[];
  selectedTable: string;
  setSelectedTable: (tableName: string) => void;
  tableData: typeof USERS_MOCK;
}) {
  const getBgColor = (index: number) => {
    const a = index % COLORS.length;
    return COLORS[a];
  };

  return (
    <>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          gap: '8px',
          marginBottom: '24px',

          ' .tableButton': {
            fontFamily: 'Avenir600',
            fontSize: '16px',
            borderRadius: '12px',
            backgroundColor: '#393746',
            height: '46px',
            textTransform: 'none',
            padding: 0,
            width: '100%',
            boxShadow: 'none',
          },
          ' .tableButtonSelected': {
            fontFamily: 'Avenir600',
            fontSize: '16px',
            borderRadius: '12px',
            outline: '2px solid #007AFF',
            background: 'rgba(0, 122, 255, 0.20)',
            height: '46px',
            textTransform: 'none',
            padding: 0,
            width: '100%',
            boxShadow: 'none',
            '&:hover': {
              background: 'rgba(0, 122, 255, 0.20)',
            },
          },
        }}
      >
        {tables.map((tableName) => (
          <Button
            key={tableName}
            variant="contained"
            fullWidth
            className={selectedTable === tableName ? 'tableButtonSelected' : 'tableButton'}
            onClick={() => {
              setSelectedTable(tableName);
            }}
          >
            {tableName}
          </Button>
        ))}
      </Box>
      <Box
        sx={{
          width: '100%',
        }}
      >
        {tableData.map((user, index) => (
          <Box
            key={user.name}
            sx={{
              borderTop: '1px solid #24232A',
              display: 'flex',
              padding: '12px 0',
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Avenir600',
                fontSize: '16px',
                lineHeight: 'normal',
                color: '#676577',
                width: '20px',
                //  margin: '16px 0',
              }}
            >
              {index + 1}
            </Typography>
            <Box
              sx={{
                borderRadius: '50%',
                minWidth: '48px',
                minHeight: '48px',
                background: getBgColor(index),
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '0 8px',
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Avenir600',
                  fontSize: '16px',
                  lineHeight: 'normal',
                  color: '#fff',
                }}
              >
                {user.name.split(' ')[0]?.slice(0, 1)}
                {user.name.split(' ')[1]?.slice(0, 1)}
              </Typography>
            </Box>
            <Typography
              sx={{
                flexGrow: 1,
                fontFamily: 'Avenir600',
                fontSize: '16px',
                lineHeight: 'normal',
                color: '#fff',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {user.name}
            </Typography>
            <Box
              sx={{
                borderRadius: '16px',
                background: '#24232A',
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
                padding: '8px',
                marginLeft: '8px',
                maxWidth: '104px',
                minWidth: '104px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  gap: '8px',
                  alignItems: 'center',
                }}
              >
                <UpIcon />
                <Typography
                  sx={{
                    fontFamily: 'Avenir600',
                    fontSize: '14px',
                    lineHeight: 'normal',
                    color: '#fff',
                  }}
                >
                  {user.score}x
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  gap: '8px',
                  alignItems: 'center',
                }}
              >
                <TonCoin />
                <Typography
                  sx={{
                    fontFamily: 'Avenir600',
                    fontSize: '14px',
                    lineHeight: 'normal',
                    color: '#fff',
                  }}
                >
                  {user.tonAmount}
                </Typography>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </>
  );
}

export default LeaderboardTable;
