import React from 'react';
import { Box } from '@mui/material';

function BuyTon() {
  return (
    <Box
      sx={{
        minHeight: '1000px',
        borderRadius: '24px',
        background: '#24232A',
        width: '100%',
        maxWidth: 'calc(100% - 48px)',
        overflow: 'hidden',
        color: 'white',
        padding: '24px',
      }}
    ></Box>
  );
}

export default BuyTon;
