import { APP_NETWORKS } from '../utils/constants';
import { decrypt_pub, encrypt_pub } from '../utils/encrypt';

const SECURE_URLS = {
  getDeposit: 'https://getdepositsecure-dxpzoyigsa-uc.a.run.app',
  createDepositRequest: 'https://createdepositrequestsecure-dxpzoyigsa-uc.a.run.app',
  checkTelegram: 'https://ds-dxpzoyigsa-uc.a.run.app',
  getStatus: 'https://getstatussecure-dxpzoyigsa-uc.a.run.app',
  // bid: 'https://bidsecure-dxpzoyigsa-uc.a.run.app', // not in use
};

export const fetchBalance = (
  id: number
): Promise<{ network: keyof typeof APP_NETWORKS; deposit: number } | { error: string }> =>
  fetch(SECURE_URLS.getDeposit, {
    method: 'POST',
    headers: {
      'Content-Type': 'text/plain',
      'x-api-token': process.env.REACT_APP_X_API_TOKEN || '',
    },
    body: encrypt_pub(JSON.stringify({ id })),
  })
    .then((res) => res.text())
    .then((res) => {
      return JSON.parse(decrypt_pub(res));
    })
    .catch((err) => {
      console.log('fetchBalance err', err);
    });

export const fetchDepositRequest = (id: number): Promise<{ network: keyof typeof APP_NETWORKS; reference: string }> =>
  fetch(SECURE_URLS.createDepositRequest, {
    method: 'POST',
    headers: {
      'Content-Type': 'text/plain',
      'x-api-token': process.env.REACT_APP_X_API_TOKEN || '',
    },
    body: encrypt_pub(JSON.stringify({ id })),
  })
    .then((res) => res.text())
    .then((res) => {
      return JSON.parse(decrypt_pub(res));
    })
    .catch((err) => {
      console.log('fetchBalance err', err);
    });

export const fetchTelegramData = (query: string) =>
  fetch(SECURE_URLS.checkTelegram, {
    method: 'POST',
    headers: {
      'Content-Type': 'text/plain',
      'x-api-token': process.env.REACT_APP_X_API_TOKEN || '',
    },
    body: encrypt_pub(JSON.stringify({ d: query })),
  })
    .then((res) => res.text())
    .then((res) => {
      return JSON.parse(res);
    })
    .catch((err) => {
      console.log('fetchTelegramData err', err);
    });

export const getStatusSecure = (id: number): Promise<{ token: string; uid: string }> =>
  fetch(SECURE_URLS.getStatus, {
    method: 'POST',
    headers: {
      'Content-Type': 'text/plain',
      'x-api-token': process.env.REACT_APP_X_API_TOKEN || '',
    },
    body: encrypt_pub(JSON.stringify({ id })),
  })
    .then((res) => res.text())
    .then((res) => {
      return JSON.parse(decrypt_pub(res));
    })
    .catch((err) => {
      console.log('getStatusSecure err', err);
    });
