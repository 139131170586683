export const USERS_MOCK = [
  {
    name: 'Angela Bower',
    score: 9080,
    tonAmount: 2807.19,
    fill: 'linear-gradient(225deg, #FF5E62 14.58%, #F96 85.42%)',
  },
  {
    name: 'Lynn Tanneasdr',
    score: 3035.26,
    tonAmount: 967.13,
    fill: 'linear-gradient(225deg, #38EF7D 14.58%, #11998E 85.42%)',
  },
  {
    name: 'Capt. Trunk',
    score: 2994.43,
    tonAmount: 1115.04,
    fill: 'linear-gradient(225deg, #1565C0 14.58%, #B92B27 85.42%)',
  },
  {
    name: 'Peter Thornton',
    score: 1598.24,
    tonAmount: 411.38,
    fill: 'linear-gradient(225deg, #021B79 14.58%, #0575E6 85.42%)',
  },
  { name: 'Link', score: 4175, tonAmount: 2442.55, fill: 'linear-gradient(225deg, #FF5E62 14.58%, #F96 85.42%)' },
  { name: 'Mario', score: 5934, tonAmount: 2194.09, fill: 'linear-gradient(225deg, #38EF7D 14.58%, #11998E 85.42%)' },
  {
    name: 'Master Chief',
    score: 5465,
    tonAmount: 1439.81,
    fill: 'linear-gradient(225deg, #1565C0 14.58%, #B92B27 85.42%)',
  },
  { name: 'Sonic', score: 6157, tonAmount: 2001.22, fill: 'linear-gradient(225deg, #021B79 14.58%, #0575E6 85.42%)' },
  { name: 'Kratos', score: 7431, tonAmount: 2577.92, fill: 'linear-gradient(225deg, #FF5E62 14.58%, #F96 85.42%)' },
  {
    name: 'Ezio Auditore',
    score: 8229,
    tonAmount: 1088.12,
    fill: 'linear-gradient(225deg, #38EF7D 14.58%, #11998E 85.42%)',
  },
  {
    name: 'Nathan Drake',
    score: 2387,
    tonAmount: 1042.29,
    fill: 'linear-gradient(225deg, #1565C0 14.58%, #B92B27 85.42%)',
  },
  {
    name: 'Geralt of Rivia',
    score: 1902,
    tonAmount: 2925.83,
    fill: 'linear-gradient(225deg, #021B79 14.58%, #0575E6 85.42%)',
  },
  { name: 'Samus Aran', score: 3531, tonAmount: 1497.08, fill: 'linear-gradient(225deg, #FF5E62 14.58%, #F96 85.42%)' },
  {
    name: 'Solid Snake',
    score: 6820,
    tonAmount: 1160.32,
    fill: 'linear-gradient(225deg, #38EF7D 14.58%, #11998E 85.42%)',
  },
  { name: 'Cortana', score: 8217, tonAmount: 1318.18, fill: 'linear-gradient(225deg, #1565C0 14.58%, #B92B27 85.42%)' },
  {
    name: 'Lara Croft',
    score: 8108,
    tonAmount: 2577.47,
    fill: 'linear-gradient(225deg, #021B79 14.58%, #0575E6 85.42%)',
  },
  { name: 'Pikachu', score: 3638, tonAmount: 1069.74, fill: 'linear-gradient(225deg, #FF5E62 14.58%, #F96 85.42%)' },
  {
    name: 'Donkey Kong',
    score: 1374,
    tonAmount: 2874.15,
    fill: 'linear-gradient(225deg, #38EF7D 14.58%, #11998E 85.42%)',
  },
  { name: 'Yoshi', score: 2783, tonAmount: 2995.46, fill: 'linear-gradient(225deg, #1565C0 14.58%, #B92B27 85.42%)' },
  { name: 'Spyro', score: 6513, tonAmount: 1897.89, fill: 'linear-gradient(225deg, #021B79 14.58%, #0575E6 85.42%)' },
  { name: 'Megaman', score: 7234, tonAmount: 1722.63, fill: 'linear-gradient(225deg, #FF5E62 14.58%, #F96 85.42%)' },
  {
    name: 'Cloud Strife',
    score: 3837,
    tonAmount: 2276.19,
    fill: 'linear-gradient(225deg, #38EF7D 14.58%, #11998E 85.42%)',
  },
  {
    name: 'Sub-Zero',
    score: 6843,
    tonAmount: 1263.65,
    fill: 'linear-gradient(225deg, #1565C0 14.58%, #B92B27 85.42%)',
  },
  { name: 'Sora', score: 6664, tonAmount: 2353.23, fill: 'linear-gradient(225deg, #021B79 14.58%, #0575E6 85.42%)' },
  { name: 'Doomguy', score: 1496, tonAmount: 922.18, fill: 'linear-gradient(225deg, #FF5E62 14.58%, #F96 85.42%)' },
  {
    name: 'Crash Bandicoot',
    score: 7840,
    tonAmount: 2257.16,
    fill: 'linear-gradient(225deg, #38EF7D 14.58%, #11998E 85.42%)',
  },
  { name: 'Rayman', score: 4902, tonAmount: 1498.34, fill: 'linear-gradient(225deg, #1565C0 14.58%, #B92B27 85.42%)' },
  {
    name: 'Gordon Freeman',
    score: 8117,
    tonAmount: 2485.35,
    fill: 'linear-gradient(225deg, #021B79 14.58%, #0575E6 85.42%)',
  },
  { name: 'Ryu', score: 1931, tonAmount: 1958.13, fill: 'linear-gradient(225deg, #FF5E62 14.58%, #F96 85.42%)' },
  { name: 'Chun-Li', score: 5369, tonAmount: 993.04, fill: 'linear-gradient(225deg, #38EF7D 14.58%, #11998E 85.42%)' },
  { name: 'GlaDOS', score: 8241, tonAmount: 2604.51, fill: 'linear-gradient(225deg, #1565C0 14.58%, #B92B27 85.42%)' },
  { name: 'Zelda', score: 2756, tonAmount: 1026.76, fill: 'linear-gradient(225deg, #021B79 14.58%, #0575E6 85.42%)' },
  {
    name: 'Altair Ibn-La`Ahad',
    score: 2158,
    tonAmount: 2147.84,
    fill: 'linear-gradient(225deg, #FF5E62 14.58%, #F96 85.42%)',
  },
  {
    name: 'Sephiroth',
    score: 5538,
    tonAmount: 2316.17,
    fill: 'linear-gradient(225deg, #38EF7D 14.58%, #11998E 85.42%)',
  },
];

export const COLORS = [
  'linear-gradient(225deg, #FF5E62 14.58%, #F96 85.42%)',
  'linear-gradient(225deg, #38EF7D 14.58%, #11998E 85.42%)',
  'linear-gradient(225deg, #1565C0 14.58%, #B92B27 85.42%)',
  'linear-gradient(225deg, #021B79 14.58%, #0575E6 85.42%)',
  'linear-gradient(225deg, #FF5E62 14.58%, #F96 85.42%)',
  'linear-gradient(225deg, #2C3E50 14.58%, #BDC3C7 85.42%)',
  'linear-gradient(225deg, #6DD5ED 14.58%, #2193B0 85.42%)',
  'linear-gradient(225deg, #F7797D 14.58%, #FBD786 49.29%, #C6FFDD 85.42%)',
  'linear-gradient(225deg, #4286F4 14.58%, #373B44 85.42%)',
  'linear-gradient(225deg, #99F2C8 14.58%, #1F4037 85.42%)',
  'linear-gradient(225deg, #96C93D 14.58%, #00B09B 85.42%)',
  'linear-gradient(225deg, #F5AF19 14.58%, #F12711 85.42%)',
  'linear-gradient(225deg, #D3CBB8 14.58%, #6D6027 85.42%)',
  'linear-gradient(225deg, #3C1053 14.58%, #AD5389 85.42%)',
  'linear-gradient(225deg, #8E44AD 14.58%, #C0392B 85.42%)',
  'linear-gradient(225deg, #A2AB58 14.58%, #636363 85.42%)',
  'linear-gradient(225deg, #F2C94C 14.58%, #F2994A 85.42%)',
  'linear-gradient(225deg, #43C6AC 14.58%, #191654 85.42%)',
  'linear-gradient(225deg, #FFFF1C 14.58%, #00C3FF 85.42%)',
  'linear-gradient(225deg, #6F0 14.58%, #A80077 85.42%)',
];
