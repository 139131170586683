import { Box, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { ReactComponent as TonCoin } from '../assets/svg/ton_bet_icon.svg';

const Container = (props: any) => <div>{props.children}</div>;

const PlaceBetToast = (betValue: number | null, text: string) => {
  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontFamily: 'Avenir600',
          fontSize: '16px',
          lineHeight: 'normal',
          color: '#111015',
        }}
      >
        {betValue && (
          <>
            {betValue} <TonCoin style={{ margin: '0 8px 0 4px' }} />{' '}
          </>
        )}
        {text}
      </Box>
    </Container>
  );
};

export const addPlaceBetToast = (betValue: number | null, text: string) => {
  toast.dismiss();

  toast(PlaceBetToast(betValue, text), {
    hideProgressBar: true,
    closeButton: false,
    position: 'top-center',
    autoClose: 1000,
  });
};

const WinToast = (winValue: number) => {
  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          fontFamily: 'Avenir600',
          fontSize: '16px',
          lineHeight: 'normal',
          color: 'white',
          height: '56px',
          background: '#007AFF',
          padding: '0 16px',
        }}
      >
        Congratulations! You win!
        <Box
          sx={{
            borderRadius: '20px',
            background: '#FFF',
            padding: '4px 12px 4px 4px',
            maxHeight: '32px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '6px',
          }}
        >
          <TonCoin />
          <Typography
            sx={{
              fontFamily: 'Avenir600',
              fontSize: '14px',
              lineHeight: 'normal',
              color: '#111015',
            }}
          >
            +{winValue}
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export const addWinToast = (winValue: number) => {
  toast.dismiss();

  toast(WinToast(winValue), {
    hideProgressBar: true,
    closeButton: false,
    position: 'top-center',
    autoClose: 1000,
  });
};
