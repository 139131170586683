import { createContext, useEffect, useRef, useState } from 'react';
import {
  GameDataType,
  IConnectionContext,
  PlaceBetState,
  RegisteredBet,
  ShortRoundResult,
} from './ConnectionProvider.types';
import { AutoPlayValuesType, ROUND_RESULTS_STORAGE_KEY, WEB_SOCKET_URL } from '../../utils/constants';
import { addPlaceBetToast, addWinToast } from '../../utils/toast';
import { useTelegram } from '../TelegramProvider/TelegramProvider';
import { fetchBalance, getStatusSecure } from '../../api/requests';
import { truncNumber } from '../../utils/numbers';
import { useNavigatorOnLine } from '../../hooks/useNavigatorOnLine';
import { useAutoPlay } from '../AutoplayProvider/AutoplayProvider.hooks';
import { checkIsNeedToStop } from '../../utils/autoplay';
export const ConnectionContext = createContext<IConnectionContext | null>(null);

export const ConnectionProvider = ({ children }: { children: React.ReactNode }) => {
  const [isWsConnectionReady, setWsConnectionReady] = useState(false);
  const [gameData, setGameData] = useState<GameDataType | null>(null);
  const storageRoundResults = localStorage.getItem(ROUND_RESULTS_STORAGE_KEY);
  const [roundResults, setRoundResults] = useState<ShortRoundResult[]>(
    storageRoundResults ? JSON.parse(storageRoundResults) : []
  );
  const [currentRoundResult, setCurrentRoundResult] = useState<ShortRoundResult | null>(null);
  const [placeBetState, setPlaceBetState] = useState<PlaceBetState>(PlaceBetState.PLACE_BET);
  const [roundCashOutValue, setRoundCashOutValue] = useState<null | number>(null);
  const [jwtToken, setJwtToken] = useState<null | string>(null);
  const [userUid, setUserUid] = useState<null | string>(null);
  const [userBalance, setUserBalance] = useState<number | null>(null);
  const [userNetwork, setUserNetwork] = useState<string | null>(null);
  const [isUserNotFound, setUserNotFound] = useState<boolean>(false);
  const [registeredBet, setRegisteredBet] = useState<RegisteredBet | null>(null);
  const [startedRoundId, setStartedRoundId] = useState<number | null>(null);
  const [autoPlayData, setAutoPlayData] = useState<AutoPlayValuesType | null>(null);
  const { telegramUserId } = useTelegram();
  const { autoPlaySettings, setAutoPlayOn } = useAutoPlay();
  const onLineStatus = useNavigatorOnLine();
  const ws = useRef<WebSocket | null>(null);

  const saveRoundResultsToStorage = (newResult: ShortRoundResult) => {
    setRoundResults((prev) => {
      const newResults = [...prev, newResult];
      localStorage.setItem(ROUND_RESULTS_STORAGE_KEY, JSON.stringify(newResults));

      return newResults;
    });
  };

  // UPDATE USER BALANCE
  const updateUserBalance = async () => {
    if (telegramUserId) {
      const result = await fetchBalance(telegramUserId);
      if ('deposit' in result) {
        // console.log('result.deposit', result.deposit);
        setUserBalance(result.deposit);
      }
      if ('network' in result) {
        setUserNetwork(result.network);
      } else if ('error' in result) {
        if (result.error === 'Not found') {
          setUserNotFound(true);
        }
      }
    }
  };

  // GET BALANCE
  useEffect(() => {
    if (telegramUserId) {
      updateUserBalance();
    }
  }, [telegramUserId]);

  // GET JWT TOKEN & UID
  useEffect(() => {
    if (telegramUserId) {
      const getTokens = async () => {
        const result = await getStatusSecure(telegramUserId);
        if ('token' in result) {
          setJwtToken(result.token);
        }
        if ('uid' in result) {
          setUserUid(result.uid);
        }
      };
      getTokens();
    }
  }, [telegramUserId]);

  // handle win
  useEffect(() => {
    if (currentRoundResult !== null) {
      if (roundResults[roundResults.length - 1]?.round_id !== currentRoundResult.round_id) {
        saveRoundResultsToStorage(currentRoundResult);
        addWinToast(truncNumber(currentRoundResult.bet_val));
        setRegisteredBet(null);
        const newAutoplayData = autoPlayData
          ? {
              ...autoPlayData,
              winAmount: autoPlayData.winAmount + currentRoundResult.bet_val,
              maxWin:
                currentRoundResult.bet_val > autoPlayData.maxWin ? currentRoundResult.bet_val : autoPlayData.maxWin,
            }
          : autoPlayData;

        if (autoPlayData && gameData && newAutoplayData) {
          if (checkIsNeedToStop(autoPlaySettings, newAutoplayData, gameData)) {
            setAutoPlayOn(false);
            setAutoPlayData(null);
          } else {
            setAutoPlayData(newAutoplayData);
          }
        }

        setTimeout(() => {
          updateUserBalance();
        }, 300);
      }
    }
  }, [currentRoundResult]);

  // handle lose
  useEffect(() => {
    if (registeredBet) {
      saveRoundResultsToStorage({
        round_id: registeredBet.round_id,
        bet_val: -registeredBet.bet_val,
      });
      setRegisteredBet(null);
      setAutoPlayData((prev) => {
        if (prev) {
          return {
            ...prev,
            loseAmount: prev.loseAmount + registeredBet.bet_val,
          };
        }
        return prev;
      });
      setTimeout(() => {
        updateUserBalance();
      }, 300);
    }
  }, [gameData?.round_id]);

  // handle round start
  useEffect(() => {
    if (gameData && userUid) {
      const currentRoundBet = gameData.round_bets?.[userUid]?.[1];
      const roundResult = gameData.round_bets?.[userUid]?.[1].result;
      if (currentRoundBet && !roundResult) {
        setUserBalance((prev) => (prev ? prev - currentRoundBet.bet_val : null));
      }
    }
  }, [startedRoundId]);

  const connectSocket = () => {
    const socket = new WebSocket(WEB_SOCKET_URL);
    socket.onopen = () => setWsConnectionReady(true);
    socket.onclose = () => setWsConnectionReady(false);
    ws.current = socket;
  };

  const disconnectSocket = () => {
    ws.current?.close();
  };

  useEffect(() => {
    connectSocket();
    return disconnectSocket;
  }, []);

  useEffect(() => {
    if (isWsConnectionReady && ws.current && userUid) {
      ws.current.onmessage = (event) => {
        const data: GameDataType = JSON.parse(event.data);
        setGameData(data);

        const x = data.x;
        const round_bets = data.round_bets;
        const next_round_bets = data.next_round_bets;

        const isPlacedCurrentBet1 = round_bets?.[userUid]?.[1] || null;
        const isPlacedNextBet1 = next_round_bets?.[userUid]?.[1] || null;

        const roundResult1 = isPlacedCurrentBet1 ? round_bets?.[userUid]?.[1].result : null;
        const roundResultPlusBet = isPlacedCurrentBet1 ? round_bets?.[userUid]?.[1].result_plus_bet : null;

        // console.log('data.round_bets', data.round_bets);
        // console.log('data.next_round_bets', data.next_round_bets);
        // console.log('roundResultPlusBet',roundResultPlusBet);

        if (roundResultPlusBet !== null) {
          setCurrentRoundResult({
            round_id: data.round_id,
            bet_val: roundResultPlusBet,
          });
        } else {
          setCurrentRoundResult(null);
        }

        if (data.x > 0) {
          setStartedRoundId(data.round_id);
        }

        if (isPlacedCurrentBet1 && x > 0) {
          if (round_bets?.[userUid]?.[1].is_taken) {
            setRegisteredBet(null);
          } else {
            setRegisteredBet({
              bet_val: round_bets?.[userUid]?.[1].bet_val,
              round_id: round_bets?.[userUid]?.[1].round_id,
              id: round_bets?.[userUid]?.[1].id,
            });
          }
        }

        if (x < 0) {
          if (isPlacedCurrentBet1) {
            setPlaceBetState(PlaceBetState.CANCEL_BET);
          } else {
            setPlaceBetState(PlaceBetState.PLACE_BET);
          }
        } else if (x > 0) {
          if (isPlacedCurrentBet1 && roundResult1 === null) {
            const betVal = round_bets?.[userUid]?.[1].bet_val;
            const cashOutResult = betVal * data.x;
            setRoundCashOutValue(cashOutResult);
            setPlaceBetState(PlaceBetState.CASH_OUT);
          } else if (!isPlacedNextBet1) {
            setPlaceBetState(PlaceBetState.PLACE_BET);
          } else {
            setPlaceBetState(PlaceBetState.CANCEL_BET);
          }
        }
      };
    }
  }, [isWsConnectionReady, ws, userUid]);

  // reconnect socket
  useEffect(() => {
    if (ws.current?.readyState === WebSocket.CLOSED && onLineStatus) {
      connectSocket();
    }
  }, [ws.current?.readyState, onLineStatus]);

  useEffect(() => {
    const intervalId = setInterval(
      function () {
        if (ws.current?.readyState === WebSocket.OPEN) {
          ws.current.send(
            JSON.stringify({
              game: 'crash',
              action: 'get_round_state',
            })
          );
        }
      },
      process.env.NODE_ENV === 'development' ? 50 : 50
    );
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const action = (betValue: number, autoTakeVal?: number) => {
    switch (placeBetState) {
      case PlaceBetState.PLACE_BET: {
        if (gameData && gameData.x < 0) {
          addPlaceBetToast(betValue, 'bet is placed');
        } else {
          addPlaceBetToast(betValue, 'bet is placed for the next round');
        }
        break;
      }

      case PlaceBetState.CANCEL_BET: {
        addPlaceBetToast(null, 'Your bet is canceled');
        break;
      }
    }
    const dataToSend = {
      jwt: jwtToken,
      game: 'crash',
      action: 'bet_btn',
      bet_num: 1, // 1 or 2
      auto_take: autoTakeVal ? true : false, // true or false , use check boxes for it
      auto_take_val: autoTakeVal, // value of X for auto take, use inputs for it
      bet_val: betValue, // use unputs for it
    };
    console.log('dataToSend', dataToSend);
    ws.current?.send(JSON.stringify(dataToSend));
  };

  const value: IConnectionContext = {
    gameData,
    placeBetState,
    roundCashOutValue,
    action,
    ws,
    roundResults,
    userBalance,
    isUserNotFound,
    userNetwork,
    isWsConnectionReady,
    autoPlayData,
    setAutoPlayData,
  };

  return <ConnectionContext.Provider value={{ ...value }}>{children}</ConnectionContext.Provider>;
};
