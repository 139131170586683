import { useState, ReactNode, createContext, FC, useEffect } from 'react';
import { ConfigType, GameContextType } from './AutoplayProvider.types';
import { fetchDepositRequest, fetchTelegramData } from '../../api/requests';
import { useTelegram } from '../TelegramProvider/TelegramProvider';
import { useTonConnectUI, SendTransactionRequest, ConnectedWallet } from '@tonconnect/ui-react';
import { APP_NETWORKS, APP_VIEWS, AUTOPLAY_DEFAULT_SETTINGS, AutoPlaySettingsType, AutoPlayValuesType, BET_VALUES } from '../../utils/constants';
import { useConnection } from '../ConnectionProvider/ConnectionProvider.hooks';

export const AutoplayContext = createContext<GameContextType | null>(null);

export const AutoplayProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [autoPlaySettings, setAutoPlaySettings] = useState<AutoPlaySettingsType>(AUTOPLAY_DEFAULT_SETTINGS);
  const [isAutoPlayOn, setAutoPlayOn] = useState(false);

  const value: GameContextType = {
    isAutoPlayOn,
    setAutoPlayOn,
    autoPlaySettings,
    setAutoPlaySettings,
  };

  return <AutoplayContext.Provider value={value}>{children}</AutoplayContext.Provider>;
};
