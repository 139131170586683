import React, { useMemo, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { ReactComponent as LeaderboardCup } from './assets/leaderboard_cup.svg';
import { USERS_MOCK } from '../../../../utils/mock';
import { useGameState } from '../../../../providers/GameProvider/GameProvider.hooks';
import { APP_VIEWS } from '../../../../utils/constants';
import LeaderboardTable from '../../../common/LeaderboardTable/LeaderboardTable';

function MainLeaderboard() {
  const TABLES = ['Hour', 'Day', 'Week', 'All time'];
  const [selectedTable, setSelectedTable] = useState(TABLES[0]);
  const { setAppView } = useGameState();

  const data = useMemo(() => {
    switch (selectedTable) {
      case TABLES[0]: {
        return USERS_MOCK.slice(0, 5);
      }
      case TABLES[1]: {
        return USERS_MOCK.slice(5, 10);
      }
      case TABLES[2]: {
        return USERS_MOCK.slice(10, 15);
      }
      case TABLES[3]: {
        return USERS_MOCK.slice(15, 20);
      }
      default: {
        return [];
      }
    }
  }, [selectedTable]);

  return (
    <Box
      sx={{
        borderTop: '1px solid #24232A',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '40px 24px 48px',
        }}
      >
        <LeaderboardCup />
        <Typography
          sx={{
            fontFamily: 'Avenir600',
            fontSize: '20px',
            lineHeight: 'normal',
            color: '#fff',
            textAlign: 'center',
            margin: '16px 0',
          }}
        >
          Hall of Fame
          <br />
          Top Winnings on Jetlix
        </Typography>

        <LeaderboardTable
          tableData={data}
          selectedTable={selectedTable}
          setSelectedTable={setSelectedTable}
          tables={TABLES}
        ></LeaderboardTable>

        <Button
          // disabled
          onClick={() => {
            setAppView(APP_VIEWS.LEADERBOARD);
          }}
          sx={{
            height: '54px',
            width: '100%',
            fontFamily: 'Avenir600',
            fontSize: '16px',
            borderRadius: '12px',
            background: '#007AFF',
            textTransform: 'none',
            marginTop: '12px',
            color: 'white',
            '&:hover': {
              backgroundColor: '#007AFF',
            },
          }}
        >
          View full leaderboard
        </Button>
      </Box>
    </Box>
  );
}

export default MainLeaderboard;
