import React from 'react';
import { Box } from '@mui/material';
import { useTonConnectUI } from '@tonconnect/ui-react';

function Withdraw() {
  const [tonConnectUI] = useTonConnectUI();

  return (
    <Box
      sx={{
        minHeight: '1000px',
        borderRadius: '24px',
        background: '#24232A',
        width: '100%',
        maxWidth: 'calc(100% - 48px)',
        overflow: 'hidden',
        color: 'white',
        padding: '24px',

        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
      }}
    >
      <button
        onClick={async () => {
          console.log('tonConnectUI', tonConnectUI.connected);
          if(tonConnectUI.connected){
            await tonConnectUI.disconnect();
          }
        }}
      >
        .disconnect()
      </button>
    </Box>
  );
}

export default Withdraw;
