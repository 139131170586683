import React, { useEffect, useMemo, useState } from 'react';
import { Box, Typography } from '@mui/material';

import { useGameState } from '../../providers/GameProvider/GameProvider.hooks';
import { useTelegram } from '../../providers/TelegramProvider/TelegramProvider';
import { APP_VIEWS } from '../../utils/constants';
import { ReactComponent as LeaderboardCup } from '../Main/components/MainLeaderboard/assets/leaderboard_cup.svg';
import background from './assets/leaderboard_background_image.webp';
import Footer from '../common/Footer';
import { USERS_MOCK } from '../../utils/mock';
import LeaderboardTable from '../common/LeaderboardTable/LeaderboardTable';

function Leaderboard() {
  const { setAppView } = useGameState();
  const { webApp } = useTelegram();
  const TABLES = ['Hour', 'Day', 'Week', 'All time'];
  const [selectedTable, setSelectedTable] = useState(TABLES[0]);

  const data = useMemo(() => {
    switch (selectedTable) {
      case TABLES[0]: {
        return USERS_MOCK;
      }
      case TABLES[1]: {
        return USERS_MOCK.slice(10, USERS_MOCK.length);
      }
      case TABLES[2]: {
        return USERS_MOCK.slice(15, USERS_MOCK.length);
      }
      case TABLES[3]: {
        return USERS_MOCK.reverse();
      }
      default: {
        return [];
      }
    }
  }, [selectedTable]);

  useEffect(() => {
    if (webApp) {
      webApp.BackButton.onClick(() => {
        webApp.BackButton.hide();
        setAppView(APP_VIEWS.MAIN);
      });
      webApp.BackButton.show();
    }
  }, [webApp, setAppView]);

  return (
    <Box
      sx={{
        maxWidth: '700px',
        margin: 'auto',
      }}
    >
      <Box
        sx={{
          backgroundImage: `url(${background})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          padding: '40px 24px',
        }}
      >
        <LeaderboardCup />
        <Typography
          sx={{
            fontFamily: 'Avenir600',
            fontSize: '20px',
            lineHeight: 'normal',
            color: '#fff',
            textAlign: 'center',
            margin: '16px 0',
          }}
        >
          Hall of Fame
          <br />
          Top Winnings on Jetlix
        </Typography>
        <LeaderboardTable
          tableData={data}
          selectedTable={selectedTable}
          setSelectedTable={setSelectedTable}
          tables={TABLES}
        ></LeaderboardTable>
      </Box>
      <Footer></Footer>
    </Box>
  );
}

export default Leaderboard;
