export const APP_NETWORKS = {
  MAINNET: 'mainnet',
  TESTNET: 'testnet',
};

export const APP_VIEWS = {
  MAIN: 'MAIN',
  BALANCE: 'BALANCE',
  LEADERBOARD: 'LEADERBOARD',
  FORTUNE_WHEEL: 'FORTUNE_WHEEL',
  HOW_TO_PLAY_JETLIX: 'HOW_TO_PLAY_JETLIX',
  HOW_TO_PLAY_FORTUNE_WHEEL: 'HOW_TO_PLAY_FORTUNE_WHEEL',
  TERMS_OF_USE: 'TERMS_OF_USE',
  PRIVACY_POLICY: 'PRIVACY_POLICY',
  ERROR: 'ERROR',
};

export const WEB_SOCKET_URL = 'wss://app1.crow-game.com/ws';

export const BET_VALUES = [0.02, 2, 5, 10];

export const ROUND_RESULTS_STORAGE_KEY = 'short_round_results';

export const HOW_TO_PLAY_MODES = {
  JETLIX: 'JETLIX',
  FORTUNE_WHEEL: 'FORTUNE_WHEEL',
};

export enum PrizeTypes {
  TON = 'TON',
  SPIN_TICKETS = 'SPIN_TICKETS',
  MULTI_TON = 'MULTI_TON',
  EMPTY = 'EMPTY',
}

export type PrizeType = {
  type: keyof typeof PrizeTypes;
  value: number | null;
};

export const AUTOPLAY_ROUNDS: Array<'infinite' | number> = ['infinite', 5, 10, 15, 20];

export const AUTOPLAY_DEFAULT_SETTINGS = {
  selectedRounds: AUTOPLAY_ROUNDS[0],
  cashOutAt: '',
  stopIfWinMoreThan: '',
  stopIfLooseMoreThan: '',
  stopIfSingleWinExceeds: '',
};

export type AutoPlaySettingsType = {
  selectedRounds: number | 'infinite';
  cashOutAt: number | string;
  stopIfWinMoreThan: number | string;
  stopIfLooseMoreThan: number | string;
  stopIfSingleWinExceeds: number | string;
};

export const AUTOPLAY_DEFAULT_VALUES = {
  startRound: null,
  winAmount: 0,
  loseAmount: 0,
};

export type AutoPlayValuesType = {
  startRound: number;
  winAmount: number;
  loseAmount: number;
  maxWin: number;
};
