import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { keyframes } from '@emotion/react';

import { useGameState } from '../../providers/GameProvider/GameProvider.hooks';
import { useTelegram } from '../../providers/TelegramProvider/TelegramProvider';
import { APP_VIEWS, PrizeType, PrizeTypes } from '../../utils/constants';
import { ReactComponent as Ticket } from './assets/ticket.svg';
import { ReactComponent as Info } from './assets/info.svg';
import background from './assets/wheel_background.webp';
import wheel from './assets/figpie.webp';
import button from './assets/button.svg';
import wheel_back_decoration from './assets/wheel_back_decoration.webp';
import wheel_front_decoration from './assets/wheel_front_decoration.webp';
import { randomElement, randomIntFromInterval } from '../../utils/numbers';
import PrizeModal from './components/PrizeModal';
import wheel_reward_tickets from './assets/wheel_reward_tickets.webp';
import wheel_reward_ton from './assets/wheel_reward_ton.webp';
import wheel_reward_ton_massive from './assets/wheel_reward_ton_massive.webp';

function FortuneWheel() {
  const { setAppView, userSpinTicketsAmount } = useGameState();
  const { webApp } = useTelegram();
  const [isBtnDisabled, setBtnDisabled] = useState(false);
  const [prize, setPrize] = useState<PrizeType | null>(null);
  const [animation, setAnimation] = useState<string | null>(null);
  const [isPrizeModalOpen, setPrizeModalOpen] = useState(false);
  const [increment, setIncrement] = useState(0);

  const prizesPositions: Record<keyof typeof PrizeTypes, number[]> = {
    EMPTY: [3, 6],
    TON: [2, 5, 8],
    SPIN_TICKETS: [1, 4, 7],
    MULTI_TON: [9],
  };

  const fakePrizes = [
    { type: PrizeTypes.SPIN_TICKETS, value: 2 },
    { type: PrizeTypes.TON, value: 10 },
    { type: PrizeTypes.EMPTY, value: null },
    { type: PrizeTypes.MULTI_TON, value: 100 },
  ];

  const handleSpin = () => {
    setBtnDisabled(true);
    const rotateKeyframes = keyframes`
    0% {
    transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  `;
    setAnimation(`${rotateKeyframes} 0.5s linear infinite`);
    setTimeout(() => {
      setPrize(fakePrizes[increment % fakePrizes.length]);
      setIncrement((prev) => prev + 1);
    }, 2000);
  };

  const handleStop = (prizeNumber: number) => {
    const prizeSize = 40;
    const fullCircle = 360;
    const newPosition = -(
      fullCircle * 2 +
      (fullCircle - (prizeNumber * prizeSize - prizeSize / 2 + randomIntFromInterval(-15, 15)))
    );
    const finishRotateKeyframes = keyframes`
    0% {
    transform: rotate(0deg);
    }
    100% {
      transform: rotate(${-newPosition}deg);
    }
  `;
    const anim = `${finishRotateKeyframes} 6s cubic-bezier(0.22, 1, 0.36, 1) forwards`;
    setAnimation(anim);
  };

  useEffect(() => {
    if (prize) {
      const prizePosition = randomElement(prizesPositions[prize.type]);
      handleStop(prizePosition);

      const getPrizeBg = () => {
        switch (prize?.type) {
          case PrizeTypes.MULTI_TON: {
            return wheel_reward_ton_massive;
          }
          case PrizeTypes.TON: {
            return wheel_reward_ton;
          }
          case PrizeTypes.SPIN_TICKETS: {
            return wheel_reward_tickets;
          }
        }
      };

      const loadImg = new Image();
      loadImg.src = getPrizeBg() || '';
      loadImg.onload = () => {
        console.log('loaded');
      };
    }
  }, [prize]);

  const handleFinish = () => {
    setBtnDisabled(false);
    switch (prize?.type) {
      case PrizeTypes.EMPTY: {
        setPrize(null);
        break;
      }
      default: {
        setPrizeModalOpen(true);
      }
    }
  };

  useEffect(() => {
    if (webApp) {
      webApp.BackButton.onClick(() => {
        webApp.BackButton.hide();
        setAppView(APP_VIEWS.MAIN);
      });
      webApp.BackButton.show();
    }
  }, [webApp, setAppView]);

  return (
    <Box
      sx={{
        maxWidth: '700px',
        margin: 'auto',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          backgroundImage: `url(${background})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Avenir600',
            fontSize: '20px',
            lineHeight: 'normal',
            color: '#fff',
            textAlign: 'center',
            marginTop: '48px',
          }}
        >
          Fortune Wheel
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Avenir400',
            fontSize: '16px',
            lineHeight: 'normal',
            color: '#fff',
            opacity: 0.5,
            textAlign: 'center',
            margin: '4px 24px 24px',
          }}
        >
          Spin the wheel of fortune and get valuable prizes and rewards
        </Typography>

        <Box
          sx={{
            width: '229px',
            height: '106px',
            borderRadius: '16px',
            background: '#43354D',
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '16px',
            position: 'relative',
          }}
        >
          <Box
            sx={{ position: 'absolute', top: '8px', right: '8px', cursor: 'pointer' }}
            onClick={() => {
              setAppView(APP_VIEWS.HOW_TO_PLAY_FORTUNE_WHEEL);
            }}
          >
            <Info />
          </Box>
          <Ticket />
          <Typography
            sx={{
              fontFamily: 'Avenir600',
              fontSize: '14px',
              lineHeight: 'normal',
              color: '#fff',
            }}
          >
            {userSpinTicketsAmount} Spin Tickets
          </Typography>
        </Box>

        <Box
          sx={{
            width: '473px',
            height: '473px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundImage: `url(${wheel_back_decoration})`,
            backgroundSize: '473px 473px',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <Box
            sx={{
              width: '473px',
              height: '473px',
              position: 'absolute',
              zIndex: 2,
              backgroundImage: `url(${wheel_front_decoration})`,
              backgroundSize: '473px 473px',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
          ></Box>

          <Box
            onAnimationEnd={handleFinish}
            sx={{
              width: '473px',
              height: '473px',
              backgroundImage: `url(${wheel})`,
              backgroundSize: '319px 319px',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              animation: animation ? animation : undefined,
            }}
          ></Box>
          <Button
            disabled={isBtnDisabled}
            sx={{
              width: '95px',
              height: '95px',
              borderRadius: '50%',
              backgroundImage: `url(${button})`,
              backgroundSize: '95px 95px',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              position: 'absolute',
              zIndex: 2,
              userSelect: 'none',
              WebkitUserSelect: 'none',
              WebkitTapHighlightColor: 'transparent',
            }}
            onClick={handleSpin}
          >
            <Typography
              sx={{
                fontFamily: 'Avenir700',
                fontSize: '20px',
                lineHeight: 'normal',
                color: '#fff',
                textAlign: 'center',
              }}
            >
              SPIN
            </Typography>
          </Button>
        </Box>
      </Box>

      <PrizeModal
        prize={prize}
        isPrizeModalOpen={isPrizeModalOpen}
        onClose={() => {
          setPrize(null);
          setPrizeModalOpen(false);
        }}
      ></PrizeModal>
    </Box>
  );
}

export default FortuneWheel;
