import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
// import { beginCell, toNano } from '@ton/ton';

import { useGameState } from '../../providers/GameProvider/GameProvider.hooks';
import { useTelegram } from '../../providers/TelegramProvider/TelegramProvider';
import { APP_VIEWS } from '../../utils/constants';

import background from './assets/balance_background_image.webp';
import { ReactComponent as TonHugeSvg } from './assets/ton_huge.svg';
import { ReactComponent as BtnDecor } from './assets/btn_decor.svg';
import Deposit from './components/Deposit';
import BuyTon from './components/BuyTon';
import Withdraw from './components/Withdraw';
import { useConnection } from '../../providers/ConnectionProvider/ConnectionProvider.hooks';

const TYPES = {
  DEPOSIT: 'Deposit',
  BUY: 'Buy TON',
  WITHDRAW: 'Withdraw',
} as const;

function Balance() {
  const [selectedType, setSelectedType] = useState<string>(TYPES.DEPOSIT);
  const { setAppView, tonExchangeRate } = useGameState();
  const { userBalance } = useConnection();
  const { webApp } = useTelegram();

  useEffect(() => {
    if (webApp) {
      webApp.BackButton.onClick(() => {
        webApp.BackButton.hide();
        setAppView(APP_VIEWS.MAIN);
      });
      webApp.BackButton.show();
    }
  }, [webApp, setAppView]);

  return (
    <Box
      sx={{
        background: '#111015',
        backgroundImage: `url(${background})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '40px 24px',
      }}
    >
      <TonHugeSvg />
      <Typography
        sx={{
          fontFamily: 'Avenir700',
          fontSize: '40px',
          lineHeight: 'normal',
          color: '#fff',
          marginTop: '20px',
        }}
      >
        {userBalance ?? '-'}
      </Typography>
      <Typography
        sx={{
          fontFamily: 'Avenir500',
          fontSize: '16px',
          lineHeight: 'normal',
          color: '#fff',
          opacity: 0.5,
        }}
      >
        ≈${Number(userBalance) * tonExchangeRate}
      </Typography>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          gap: '8px',
          margin: '24px 0 32px',
        }}
      >
        {Object.keys(TYPES).map((type) => (
          <Box
            key={type}
            sx={{
              width: '100%',
              borderRadius: '8px',
              transition: 'all 0.3s ease',
              background: selectedType === TYPES[type as keyof typeof TYPES] ? 'rgba(0, 122, 255, 0.20)' : '#24232A',
              height: '43px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxSizing: 'border-box',
              boxShadow: selectedType === TYPES[type as keyof typeof TYPES] ? 'inset 0px 0px 0px 2px #007AFF' : '',
              cursor: 'pointer',
              position: 'relative',
            }}
            onClick={() => {
              setSelectedType(TYPES[type as keyof typeof TYPES]);
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Avenir600',
                fontSize: '14px',
                lineHeight: 'normal',
                color: '#fff',
              }}
            >
              {TYPES[type as keyof typeof TYPES]}
            </Typography>
            {selectedType === TYPES[type as keyof typeof TYPES] && (
              <Box
                sx={{
                  position: 'absolute',
                  bottom: '-7px',
                }}
              >
                <BtnDecor />
              </Box>
            )}
          </Box>
        ))}
      </Box>

      {selectedType === TYPES.DEPOSIT && <Deposit />}
      {selectedType === TYPES.BUY && <BuyTon />}
      {selectedType === TYPES.WITHDRAW && <Withdraw />}
    </Box>
  );
}

export default Balance;
