import React, { useMemo } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import decor from '../../../../assets/svg/decor.svg';
import { ReactComponent as RefreshBtn } from '../../../../assets/svg/refresh.svg';
import { ReactComponent as TonDecor } from '../../../../assets/svg/ton_button.svg';
import { ReactComponent as InfiniteSvg } from './assets/infinity.svg';
import PlaceBetButton from './PlaceBetButton';
import { useGameState } from '../../../../providers/GameProvider/GameProvider.hooks';
import { BET_VALUES } from '../../../../utils/constants';
import { useConnection } from '../../../../providers/ConnectionProvider/ConnectionProvider.hooks';
import { useAutoPlay } from '../../../../providers/AutoplayProvider/AutoplayProvider.hooks';

function ControlButtons() {
  const { setSelectedBet, selectedBet, setAutoPlayModalOpen } = useGameState();
  const { gameData, autoPlayData } = useConnection();
  const { isAutoPlayOn, autoPlaySettings } = useAutoPlay();

  const roundLasts = useMemo(() => {
    if (autoPlaySettings.selectedRounds === 'infinite') {
      return <InfiniteSvg />;
    } else if (gameData?.round_id && autoPlayData) {
      const finishRoundNumber = autoPlaySettings.selectedRounds + autoPlayData.startRound;
      const result = finishRoundNumber - gameData.round_id - 1;
      return result > 0 ? result : 0;
    }
  }, [gameData?.round_id, autoPlaySettings, autoPlayData]);

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '100%',
        borderTopLeftRadius: '24px',
        borderTopRightRadius: '24px',
        backgroundColor: '#24232A',
        marginTop: '-24px',
        position: 'absolute',
      }}
    >
      <Grid
        container
        sx={{
          padding: '16px 20px',
          '& div:not(.autoplayScore)': {
            padding: '8px 4px',
          },
          ' .betButton': {
            display: 'flex',
            gap: '4px',
            fontFamily: 'Avenir600',
            fontSize: '16px',
            borderRadius: '12px',
            backgroundColor: '#393746',
            height: '46px',
            '&:hover': {
              background: '#393746',
            },
          },
          ' .betButtonSelected': {
            display: 'flex',
            gap: '4px',
            fontFamily: 'Avenir600',
            fontSize: '16px',
            borderRadius: '12px',
            border: '2px solid #007AFF',
            background: 'rgba(0, 122, 255, 0.20)',
            height: '46px',
            '&:hover': {
              background: 'rgba(0, 122, 255, 0.20)',
            },
          },
          ' .refreshButton': {
            fontFamily: 'Avenir600',
            fontSize: '16px',
            borderRadius: '12px',
            background: '#393746',
            height: '54px',
            '&:hover': {
              background: '#393746',
            },
          },
          ' .refreshButtonOn': {
            fontFamily: 'Avenir600',
            fontSize: '16px',
            borderRadius: '12px',
            height: '54px',
            border: '2px solid #007AFF',
            background: 'rgba(0, 122, 255, 0.20)',
            '&:hover': {
              background: 'rgba(0, 122, 255, 0.20)',
            },
          },
        }}
      >
        {BET_VALUES.map((bet) => (
          <Grid item xs={3} key={bet}>
            <Button
              variant="contained"
              fullWidth
              className={selectedBet === bet ? 'betButtonSelected' : 'betButton'}
              onClick={() => setSelectedBet(bet)}
              sx={{
                '& svg': {
                  minWidth: '16px',
                  minHeight: '12px',
                },
              }}
            >
              {bet}
              <TonDecor />
            </Button>
          </Grid>
        ))}
        <Grid item xs={9}>
          <PlaceBetButton></PlaceBetButton>
        </Grid>
        <Grid item xs={3}>
          <Button
            variant="contained"
            fullWidth
            className={isAutoPlayOn ? 'refreshButtonOn' : 'refreshButton'}
            onClick={() => {
              setAutoPlayModalOpen(true);
            }}
          >
            <RefreshBtn />
            {isAutoPlayOn && (
              <Box
                className="autoplayScore"
                sx={{
                  maxWidth: '32px',
                  width: '100%',
                  maxHeight: '20px',
                  height: '20px',
                  borderRadius: '20px',
                  background: 'white',
                  position: 'absolute',
                  top: '-10px',
                  right: '-12px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Avenir700',
                    fontSize: '10px',
                    lineHeight: 'normal',
                    color: '#007AFF',
                  }}
                >
                  {roundLasts}
                </Typography>
              </Box>
            )}
          </Button>
        </Grid>
      </Grid>
      <Box
        sx={{
          height: '40px',
          backgroundImage: `url(${decor})`,
          backgroundSize: 'contain',
          backgroundColor: '#111015',
          backgroundRepeat: 'repeat-x',
        }}
      ></Box>
    </Box>
  );
}

export default ControlButtons;
