import React, { useEffect } from 'react';
import { THEME, TonConnectUIProvider } from '@tonconnect/ui-react';
import { ToastContainer } from 'react-toastify';

import { GameProvider } from './providers/GameProvider/GameProvider';
import { ConnectionProvider } from './providers/ConnectionProvider/ConnectionProvider';
import { TelegramProvider } from './providers/TelegramProvider/TelegramProvider';
import { useGameState } from './providers/GameProvider/GameProvider.hooks';
import { APP_VIEWS, HOW_TO_PLAY_MODES } from './utils/constants';
import Balance from './components/Balance';
import Main from './components/Main/Main';
import Error from './components/Error';

// buffer polyfill
import { Buffer } from 'buffer';
import Leaderboard from './components/Leaderboard';
import FortuneWheel from './components/FortuneWheel';
import HowToPlay from './components/HowToPlay';
import TermsOfUse from './components/TermsOfUse';
import PrivacyPolicy from './components/PrivacyPolicy';
import { AutoplayProvider } from './providers/AutoplayProvider/AutoplayProvider';
window.Buffer = window.Buffer || Buffer;

const View = () => {
  const { appView } = useGameState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [appView]);

  switch (appView) {
    case APP_VIEWS.MAIN: {
      return <Main></Main>;
    }
    case APP_VIEWS.BALANCE: {
      return <Balance></Balance>;
    }
    case APP_VIEWS.LEADERBOARD: {
      return <Leaderboard></Leaderboard>;
    }
    case APP_VIEWS.FORTUNE_WHEEL: {
      return <FortuneWheel></FortuneWheel>;
    }
    case APP_VIEWS.HOW_TO_PLAY_JETLIX: {
      return <HowToPlay mode={HOW_TO_PLAY_MODES.JETLIX as keyof typeof HOW_TO_PLAY_MODES}></HowToPlay>;
    }
    case APP_VIEWS.HOW_TO_PLAY_FORTUNE_WHEEL: {
      return <HowToPlay mode={HOW_TO_PLAY_MODES.FORTUNE_WHEEL as keyof typeof HOW_TO_PLAY_MODES}></HowToPlay>;
    }
    case APP_VIEWS.TERMS_OF_USE: {
      return <TermsOfUse></TermsOfUse>;
    }
    case APP_VIEWS.PRIVACY_POLICY: {
      return <PrivacyPolicy></PrivacyPolicy>;
    }
    case APP_VIEWS.ERROR: {
      return <Error></Error>;
    }
    default: {
      return <div></div>;
    }
  }
};

function App() {
  const config = {};

  return (
    <TelegramProvider>
      <TonConnectUIProvider
        uiPreferences={{ theme: THEME.DARK }}
        manifestUrl={`https://jetlix.space/tonconnect-manifest.json`}
      >
        <AutoplayProvider>
        <ConnectionProvider>
          <GameProvider config={config}>
            <View />
            <ToastContainer limit={1} stacked />
          </GameProvider>
        </ConnectionProvider>
        </AutoplayProvider>
      </TonConnectUIProvider>
    </TelegramProvider>
  );
}

export default App;
