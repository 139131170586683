import React from 'react';
import {
  Box,
  Button,
  // MenuItem, Select,
  Typography,
} from '@mui/material';
// import { useTelegram } from '../../../providers/TelegramProvider/TelegramProvider';
import { useGameState } from '../../../providers/GameProvider/GameProvider.hooks';
import { TonConnectButton } from '@tonconnect/ui-react';

import { ReactComponent as TonCoin } from '../assets/ton_blue_coin.svg';
// import { ReactComponent as SelectArrow } from '../assets/select_arrow.svg';

// import tonhub_webp from '../assets/wallets/image_12.webp';
// import cryptobot_webp from '../assets/wallets/_cryptobot.webp';
import wallet_webp from '../assets/wallets/_wallet.webp';
import tonkeeper_webp from '../assets/wallets/tonkeeper.webp';
import { APP_NETWORKS } from '../../../utils/constants';
import { useConnection } from '../../../providers/ConnectionProvider/ConnectionProvider.hooks';

function Deposit() {
  const { selectedDepositAmount, setSelectedDepositAmount } = useGameState();
  const { userNetwork } = useConnection();

  const AMOUNTS = [5, 20, 50, 100];
  const { handleDepositClick } = useGameState();

  return (
    <Box
      sx={{
        borderRadius: '24px',
        background: '#24232A',
        width: '100%',
        maxWidth: 'calc(100% - 48px)',
        overflow: 'hidden',
        color: 'white',
        padding: '24px',
      }}
    >
      <Typography
        sx={{
          fontFamily: 'Avenir400',
          fontSize: '14px',
          lineHeight: 'normal',
          color: '#676577',
          marginBottom: '8px',
        }}
      >
        Deposit method
      </Typography>
      {/* <Select
        disabled
        variant="standard"
        disableUnderline
        IconComponent={SelectArrow}
        fullWidth
        value={1}
        MenuProps={{
          PaperProps: {
            sx: {
              borderRadius: '12px',
              '& .MuiMenuItem-root .itemWrapper': {
                padding: '16px 0',
                display: 'flex',
                gap: '8px',
                alignItems: 'center',
                fontFamily: 'Avenir600',
                fontSize: '16px',
                lineHeight: 'normal',
                '& img': {
                  width: '32px',
                  height: '32px',
                },
              },
            },
          },
        }}
        sx={{
          marginBottom: '16px',
          borderRadius: '12px',
          border: '1px solid #393746',
          color: '#fff',
          outline: 'none',
          '& .MuiSelect-icon': {
            right: '16px',
          },
          '&:hover': {
            outline: 'none',
          },

          fontFamily: 'Avenir600',
          fontSize: '16px',
          lineHeight: 'normal',

          '& .itemWrapper': {
            padding: '16px',
            display: 'flex',
            gap: '8px',
            alignItems: 'center',
            '& img': {
              width: '32px',
              height: '32px',
            },
          },
        }}
      >
        <MenuItem value={1}>
          <Box className="itemWrapper">
            <img src={wallet_webp} alt="Wallet"></img>
            @Wallet
          </Box>
        </MenuItem>
        <MenuItem value={2}>
          <Box className="itemWrapper">
            <img src={cryptobot_webp} alt="CryptoBot"></img>
            @CryptoBot
          </Box>
        </MenuItem>
        <MenuItem value={3}>
          <Box className="itemWrapper">
            <img src={tonkeeper_webp} alt="Tonkeeper"></img>
            Tonkeeper
          </Box>
        </MenuItem>
        <MenuItem value={4}>
          <Box className="itemWrapper">
            <img src={tonhub_webp} alt="Tonhub"></img>
            Tonhub
          </Box>
        </MenuItem>
      </Select> */}
      <Box
        sx={{
          borderRadius: '12px',
          border: '1px solid #393746',
          padding: '16px',
          display: 'flex',
          gap: '8px',
          alignItems: 'center',
          fontFamily: 'Avenir600',
          fontSize: '16px',
          lineHeight: 'normal',
          marginBottom: '16px',
          '& img': {
            width: '32px',
            height: '32px',
          },
        }}
      >
        {userNetwork === APP_NETWORKS.MAINNET ? (
          <>
            <img src={wallet_webp} alt="Wallet"></img>
            @Wallet
          </>
        ) : (
          <>
            <img src={tonkeeper_webp} alt="Wallet"></img>
            Tonkeeper (testnet)
          </>
        )}
      </Box>
      <Typography
        sx={{
          fontFamily: 'Avenir400',
          fontSize: '14px',
          lineHeight: 'normal',
          color: '#676577',
          marginBottom: '8px',
        }}
      >
        Amount to deposit
      </Typography>
      <Box
        sx={{
          border: '1px solid #393746',
          borderRadius: '12px',
          padding: '16px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '8px',
            alignItems: 'center',
            borderBottom: '1px solid #393746',
            paddingBottom: '16px',
            marginBottom: '16px',
          }}
        >
          <TonCoin />
          <Box
            sx={{
              '& input': {
                background: 'transparent',
                border: 'none',
                fontFamily: 'Avenir600',
                fontSize: '20px',
                lineHeight: 'normal',
                color: '#fff',
                '&:focus-visible': {
                  border: 'none',
                  outline: 0,
                },
              },
              '& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
                WebkitAppearance: 'none',
                margin: 0,
              },
              '& input[type=number]': {
                MozAppearance: 'textfield',
              },
            }}
          >
            <input
              size={selectedDepositAmount ? String(selectedDepositAmount).length * 1.1 : 1}
              min={0}
              onChange={(e) => {
                if (/^\d*\.?\d*$/.test(e.target.value)) {
                  setSelectedDepositAmount(e.target.value);
                }
              }}
              value={selectedDepositAmount}
            ></input>
          </Box>
          <Typography
            sx={{
              fontFamily: 'Avenir600',
              fontSize: '20px',
              lineHeight: 'normal',
              color: '#676577',
            }}
          >
            TON
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: '8px',
          }}
        >
          {AMOUNTS.map((amount) => (
            <Box
              key={amount}
              sx={{
                width: '100%',
                background: '#393746',
                borderRadius: '8px',
                padding: '8px',
                textAlign: 'center',
                fontFamily: 'Avenir600',
                fontSize: '14px',
                lineHeight: 'normal',
                color: '#fff',
                cursor: 'pointer',
              }}
              onClick={() => setSelectedDepositAmount(String(amount))}
            >
              {amount}
            </Box>
          ))}
        </Box>
      </Box>
      <Button
        variant="contained"
        fullWidth
        sx={{
          fontFamily: 'Avenir600',
          fontSize: '16px',
          borderRadius: '12px',
          background: '#007AFF',
          height: '54px',
          marginBottom: '20px',
          marginTop: '24px',
          textTransform: 'none',
          '&:hover': {
            background: '#007AFF',
          },
        }}
        onClick={() => handleDepositClick()}
      >
        Express-deposit
      </Button>
      {process.env.NODE_ENV === 'development' && <TonConnectButton />}
    </Box>
  );
}

export default Deposit;
