import { useState, ReactNode, createContext, FC, useEffect } from 'react';
import { ConfigType, GameContextType } from './GameProvider.types';
import { fetchDepositRequest, fetchTelegramData } from '../../api/requests';
import { useTelegram } from '../TelegramProvider/TelegramProvider';
import { useTonConnectUI, SendTransactionRequest, ConnectedWallet } from '@tonconnect/ui-react';
import { APP_NETWORKS, APP_VIEWS, BET_VALUES } from '../../utils/constants';
import { useConnection } from '../ConnectionProvider/ConnectionProvider.hooks';
import { useAutoPlay } from '../AutoplayProvider/AutoplayProvider.hooks';
import { PlaceBetState } from '../ConnectionProvider/ConnectionProvider.types';

export const GameContext = createContext<GameContextType | null>(null);

export const GameProvider: FC<{ config: ConfigType; children: ReactNode }> = ({ config, children }) => {
  const { beginCell, toNano } = require('@ton/ton');
  const [isNotEnoughtOpen, setNotEnoughtOpen] = useState(false);
  const [isIntroOpen, setIntroOpen] = useState(localStorage.getItem('isIntroConfirmed') !== 'true');
  const [appView, setAppView] = useState(APP_VIEWS.MAIN);
  const [isRequestForDeposit, setRequestForDeposit] = useState(false);
  const [isAutoPlayModalOpen, setAutoPlayModalOpen] = useState(false);
  const [connectedWalletAndwalletInfo, setConnectedWalletAndwalletInfo] = useState<ConnectedWallet | null>(null);
  const [selectedDepositAmount, setSelectedDepositAmount] = useState<string>('5');
  const [selectedBet, setSelectedBet] = useState(BET_VALUES[0]);
  const [userSpinTicketsAmount] = useState<number | null>(3);
  const [tonExchangeRate] = useState<number>(2.14);
  const { telegramUserId } = useTelegram();
  const [tonConnectUI] = useTonConnectUI();
  const { autoPlaySettings, isAutoPlayOn, setAutoPlayOn } = useAutoPlay();
  const { userNetwork, gameData, action, autoPlayData, setAutoPlayData, placeBetState } = useConnection();

  tonConnectUI.onStatusChange((walletAndwalletInfo) => {
    setConnectedWalletAndwalletInfo(walletAndwalletInfo);
  });

  // CHECK IF OPENED IN TELEGRAM
  useEffect(() => {
    const checkTelegram = async () => {
      const queryForCheck = window.location.hash.replace('#tgWebAppData=', '');
      console.log('queryForCheck = ', queryForCheck);
      const result = await fetchTelegramData(queryForCheck);
      console.log('fetchTelegramData', result);
      if (result?.result !== 'ok') {
        console.log('not ok');
      }
    };
    checkTelegram();
  }, []);

  const sendDepositRequest = async () => {
    if (telegramUserId) {
      const result = await fetchDepositRequest(telegramUserId);

      if ('reference' in result) {
        const destinationAddress =
          result.network === APP_NETWORKS.MAINNET
            ? process.env.REACT_APP_MAINNET_DESTINATION_ADDRESS
            : process.env.REACT_APP_TESTNET_DESTINATION_ADDRESS;

        const body = beginCell()
          .storeUint(0, 32) // write 32 zero bits to indicate that a text comment will follow
          .storeStringTail(result.reference) // write our text comment
          .endCell();

        const transaction: SendTransactionRequest = {
          validUntil: Math.floor(Date.now() / 1000) + 360,
          messages: [
            {
              address: destinationAddress || '',
              amount: toNano(Number(selectedDepositAmount)).toString(),
              payload: body.toBoc().toString('base64'),
            },
          ],
        };
        console.log('transaction', transaction);
        try {
          const trxResult = await tonConnectUI.sendTransaction(transaction);
          console.log('trxResult', trxResult);
        } catch (error) {
          console.error(' sendTransaction error', error);
        }
      }
    }
  };

  const handleDepositClick = async () => {
    if (connectedWalletAndwalletInfo) {
      sendDepositRequest();
    } else {
      setRequestForDeposit(true);
      if (userNetwork === APP_NETWORKS.MAINNET) {
        await tonConnectUI.openSingleWalletModal('telegram-wallet');
      } else {
        await tonConnectUI.openSingleWalletModal('tonkeeper');
      }
    }
  };

  useEffect(() => {
    if (isRequestForDeposit && connectedWalletAndwalletInfo) {
      sendDepositRequest();
      setRequestForDeposit(false);
    }
  }, [connectedWalletAndwalletInfo, isRequestForDeposit]);

  // AUTOPLAY
  const handleChangeAutoplayOn = (newState: boolean) => {
    if (newState && gameData) {
      setAutoPlayOn(true);
      setAutoPlayData({
        startRound: gameData.round_id + 1,
        winAmount: 0,
        loseAmount: 0,
        maxWin: 0,
      });
    } else {
      setAutoPlayOn(false);
      setAutoPlayData(null);
    }
  };

  useEffect(() => {
    if (gameData) {
      if (isAutoPlayOn && autoPlayData) {
        const takeOffValueConverted = Number(autoPlaySettings.cashOutAt.toString().replaceAll('x', ''));
        const takeOffValue = takeOffValueConverted >= 1.35 ? takeOffValueConverted : undefined;

        const isNeedToStopByWinMoreThan =
          autoPlaySettings.stopIfWinMoreThan !== ''
            ? autoPlayData?.winAmount > Number(autoPlaySettings.stopIfWinMoreThan)
            : false;
        // console.log('isNeedToStopByWinMoreThan', isNeedToStopByWinMoreThan);
        const isNeedToStopByLooseMoreThan =
          autoPlaySettings.stopIfLooseMoreThan !== ''
            ? autoPlayData?.loseAmount > Number(autoPlaySettings.stopIfLooseMoreThan)
            : false;
        // console.log('isNeedToStopByLooseMoreThan', isNeedToStopByLooseMoreThan);

        const isNeedToStopBySingleWin =
          autoPlaySettings.stopIfSingleWinExceeds !== ''
            ? autoPlayData?.maxWin > Number(autoPlaySettings.stopIfSingleWinExceeds)
            : false;
        // console.log('isNeedToStopBySingleWin', isNeedToStopBySingleWin);

        const isNeedToStopByRounds =
          autoPlaySettings.selectedRounds !== 'infinite'
            ? autoPlayData?.startRound + autoPlaySettings.selectedRounds <= gameData.round_id
            : false;
        // console.log('isNeedToStopByRounds', isNeedToStopByRounds);

        const isNeedToStop =
          isNeedToStopByWinMoreThan || isNeedToStopByLooseMoreThan || isNeedToStopBySingleWin || isNeedToStopByRounds;
        // console.log('isNeedToStop', isNeedToStop);

        if (isNeedToStop) {
          handleChangeAutoplayOn(false);
        } else {
          if (placeBetState !== PlaceBetState.CANCEL_BET) {
            action(selectedBet, takeOffValue);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameData?.round_id]);

  const value: GameContextType = {
    handleDepositClick,
    isNotEnoughtOpen,
    setNotEnoughtOpen,
    selectedDepositAmount,
    setSelectedDepositAmount,
    isIntroOpen,
    setIntroOpen,
    tonExchangeRate,
    appView,
    setAppView,
    selectedBet,
    setSelectedBet,
    userSpinTicketsAmount,
    isAutoPlayModalOpen,
    setAutoPlayModalOpen,
    handleChangeAutoplayOn,
  };

  return <GameContext.Provider value={value}>{children}</GameContext.Provider>;
};
