import React, { useState } from 'react';
import { Box } from '@mui/material';
import { SpriteAnimator } from 'react-sprite-animator';

import explode_sprite from '../assets/explode_sprite.webp';

function Explode() {
  const [opacity, setOpacity] = useState(false);
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        transition: 'all 0.7s ease-in-out',
        opacity: opacity ? 0 : 1,
      }}
    >
      <SpriteAnimator
        stopLastFrame
        onEnd={() => {
          setOpacity(true);
        }}
        sprite={explode_sprite}
        width={400}
        height={400}
        fps={6}
        scale={2}
      />
    </Box>
  );
}

export default Explode;
