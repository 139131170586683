import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import fortuneWheel from './assets/group_1303.webp';
import { useGameState } from '../../../../providers/GameProvider/GameProvider.hooks';
import { APP_VIEWS } from '../../../../utils/constants';
import { ReactComponent as Ticket } from './assets/ticket.svg';

function MainFortuneWheel() {
  const { setAppView, userSpinTicketsAmount } = useGameState();

  return (
    <Box
      sx={{
        borderTop: '1px solid #24232A',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '48px 24px 48px',
        }}
      >
        <Box
          sx={{
            width: '280px',
            height: '142px',
            backgroundImage: `url(${fortuneWheel})`,
            backgroundSize: 'cover',
          }}
        ></Box>
        <Typography
          sx={{
            fontFamily: 'Avenir600',
            fontSize: '20px',
            lineHeight: 'normal',
            color: '#fff',
            textAlign: 'center',
            margin: '24px 0 4px',
          }}
        >
          Fortune Wheel
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Avenir400',
            fontSize: '16px',
            lineHeight: 'normal',
            color: '#fff',
            opacity: 0.5,
            textAlign: 'center',
            // margin: '16px 0',
          }}
        >
          Spin and win TON cryptocurrency, Free Bets and Spin Tickets!
        </Typography>

        <Box
          sx={{
            borderRadius: '8px',
            background: '#24232A',
            padding: '12px 16px',
            display: 'flex',
            gap: '8px',
            alignItems: 'center',
            marginTop: '16px',
            marginBottom: '24px',
          }}
        >
          <Ticket />
          <Typography
            sx={{
              fontFamily: 'Avenir600',
              fontSize: '14px',
              lineHeight: 'normal',
              color: '#fff',
            }}
          >
            {userSpinTicketsAmount} Spin Tickets
          </Typography>
        </Box>

        <Button
          // disabled
          onClick={() => {
            setAppView(APP_VIEWS.FORTUNE_WHEEL);
          }}
          sx={{
            height: '54px',
            width: '100%',
            fontFamily: 'Avenir600',
            fontSize: '16px',
            borderRadius: '12px',
            background: '#007AFF',
            textTransform: 'none',
            color: 'white',
            '&:hover': {
              backgroundColor: '#007AFF',
            },
          }}
        >
          Spin the Wheel
        </Button>
      </Box>
    </Box>
  );
}

export default MainFortuneWheel;
