import { ConnectionContext } from './ConnectionProvider';
import React from 'react';

export function useConnection() {
  const context = React.useContext(ConnectionContext);

  if (!context) {
    throw new Error('use only context provider!');
  }

  return context;
}
