import React from 'react';
import { Box, Button, Modal, Slide, Typography } from '@mui/material';
import { ReactComponent as TonHugeSvg } from '../../Balance/assets/ton_huge.svg';
import background from '../../Balance/assets/balance_background_image.webp';
import { useGameState } from '../../../providers/GameProvider/GameProvider.hooks';
import { APP_VIEWS } from '../../../utils/constants';

function NotEnoughtModal() {
  const { isNotEnoughtOpen, setNotEnoughtOpen, setAppView } = useGameState();

  return (
    <Modal
      open={isNotEnoughtOpen}
      sx={{
        outline: 0,
        '& .MuiBackdrop-root': {
          background: 'rgba(17, 16, 21, 0.9)',
          outline: 0,
        },
      }}
    >
      <Slide direction="up" in={isNotEnoughtOpen}>
        <Box
          sx={{
            height: '100%',
            outline: 0,
          }}
        >
          <Box
            sx={{
              background: '#111015',
              backgroundImage: `url(${background})`,
              backgroundSize: 'contain',
              maxWidth: '600px',
              position: 'absolute',
              bottom: 0,
              left: '50%',
              transform: 'translateX(-50%)',
              width: '100%',
              borderTopRightRadius: '24px',
              borderTopLeftRadius: '24px',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <Box
              sx={{
                padding: '40px 24px 20px',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <TonHugeSvg />
              <Typography
                sx={{
                  fontFamily: 'Avenir600',
                  fontSize: '20px',
                  lineHeight: 'normal',
                  color: '#fff',
                  marginTop: '32px',
                }}
              >
                Not enought funds
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Avenir400',
                  fontSize: '16px',
                  lineHeight: 'normal',
                  color: '#676577',
                  marginTop: '8px',
                  textAlign: 'center',
                }}
              >
                Unfortunately, your balance is not enough to place this bet. Top it up with a deposit or buy
                cryptocurrency
              </Typography>

              <Button
                variant="contained"
                fullWidth
                sx={{
                  fontFamily: 'Avenir600',
                  fontSize: '16px',
                  borderRadius: '12px',
                  background: '#007AFF',
                  height: '54px',
                  textTransform: 'none',
                  marginTop: '32px',
                  '&:hover': {
                    background: '#007AFF',
                  },
                }}
                onClick={() => {
                  setAppView(APP_VIEWS.BALANCE);
                }}
              >
                Top up balance
              </Button>
              <Button
                variant="text"
                fullWidth
                sx={{
                  fontFamily: 'Avenir600',
                  fontSize: '16px',
                  borderRadius: '12px',
                  background: 'transparent',
                  height: '54px',
                  textTransform: 'none',
                  marginTop: '10px',
                  color: '#676577',
                  '&:hover': {
                    background: 'transparent',
                  },
                }}
                onClick={() => setNotEnoughtOpen(false)}
              >
                Not now
              </Button>
            </Box>
          </Box>
        </Box>
      </Slide>
    </Modal>
  );
}

export default NotEnoughtModal;
