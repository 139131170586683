import { Box, Typography } from '@mui/material';

import decor from './assets/ton_decor.svg';
import guide_btn from './assets/guide_btn.svg';
import wheel from './assets/wheel.svg';
import { ReactComponent as PlusSvg } from './assets/plus.svg';
import { useGameState } from '../../../../providers/GameProvider/GameProvider.hooks';
import { APP_VIEWS } from '../../../../utils/constants';
import { useConnection } from '../../../../providers/ConnectionProvider/ConnectionProvider.hooks';
import { truncNumber } from '../../../../utils/numbers';

function Header() {
  const { setAppView, userSpinTicketsAmount } = useGameState();
  const { userBalance, isUserNotFound } = useConnection();

  return (
    <Box
      sx={{
        width: '100%',
        position: 'absolute',
        zIndex: 1,
      }}
    >
      <Box
        sx={{
          padding: '14px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '8px',
          }}
        >
          <Box
            sx={{
              width: '36px',
              height: '36px',
              cursor: 'pointer',
              WebkitTapHighlightColor: 'transparent',
            }}
            onClick={() => {
              setAppView(APP_VIEWS.HOW_TO_PLAY_JETLIX);
            }}
          >
            <img src={guide_btn} alt="guide"></img>
          </Box>
          <Box
            sx={{
              height: '24px',
              width: '114px',
              background: '#fff',
              borderRadius: '18px',
              padding: '6px',
              display: 'flex',
              gap: '4px',
              cursor: 'pointer',
              WebkitTapHighlightColor: 'transparent',
            }}
            onClick={() => {
              setAppView(APP_VIEWS.FORTUNE_WHEEL);
            }}
          >
            <img src={wheel} alt="wheel"></img>
            <Box>
              <Typography
                sx={{
                  fontFamily: 'Avenir500',
                  fontSize: '10px',
                  color: '#787878',
                  lineHeight: 'normal',
                }}
              >
                Fortune Wheel
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Avenir600',
                  fontSize: '12px',
                  lineHeight: 'normal',
                  color: '#111015',
                }}
              >
                {userSpinTicketsAmount} tickets
              </Typography>
            </Box>
          </Box>
        </Box>

        {isUserNotFound && <span style={{ color: 'red' }}>User not found</span>}

        <Box
          sx={{
            display: 'flex',
            cursor: 'pointer',
            WebkitTapHighlightColor: 'transparent',
          }}
          onClick={() => {
            setAppView(APP_VIEWS.BALANCE);
          }}
        >
          <img src={decor} alt="decor"></img>
          <Box
            sx={{
              height: '36px',
              background: '#fff',
              borderTopRightRadius: '18px',
              borderBottomRightRadius: '18px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginLeft: '-1px',
              paddingLeft: '2px',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Avenir600',
                fontSize: '14px',
                lineHeight: 'normal',
                color: '#111015',
              }}
            >
              {userBalance ? truncNumber(userBalance) : '-'}
            </Typography>
            <Box
              sx={{
                width: '48px',
                height: '28px',
                borderRadius: '14px',
                background: '#007AFF',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginRight: '4px',
                marginLeft: '12px',
              }}
            >
              <PlusSvg />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Header;
