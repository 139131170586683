import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { keyframes } from '@emotion/react';

import space_object_1 from '../assets/space_object_1.webp';
import space_object_2 from '../assets/space_object_2.webp';
import space_object_3 from '../assets/space_object_3.webp';
import space_object_4 from '../assets/space_object_4.webp';
import space_object_5 from '../assets/space_object_5.webp';

function Stars() {
  const [spaceObject, setSpaceObject] = useState<string | null>(null);
  const [objectNumber, setObjectNumber] = useState(0);
  const spaceObjects = [space_object_1, space_object_2, space_object_3, space_object_4, space_object_5];

  useEffect(() => {
    setTimeout(() => {
      setSpaceObject(space_object_1);
      setObjectNumber((prev) => prev + 1);
    }, 3000);
  }, []);

  const handleAnimationEnd = () => {
    setSpaceObject(null);
    setTimeout(() => {
      setSpaceObject(spaceObjects[objectNumber % spaceObjects.length]);
      setObjectNumber((prev) => prev + 1);
    }, 1000);
  };

  const animationKeyframes = keyframes`
  0% {
  background-position: center -347px;
  }
  100% {
  background-position: center 347px;
  }
`;

  return (
    <Box
      onAnimationEnd={handleAnimationEnd}
      sx={{
        height: '100%',
        width: '100%',
        position: 'absolute',
        animation: spaceObject ? `${animationKeyframes} 5s linear forwards` : undefined,
        backgroundImage: `url(${spaceObject})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    ></Box>
  );
}

export default Stars;
