import React, { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { useConnection } from '../../../../providers/ConnectionProvider/ConnectionProvider.hooks';
import { truncNumber } from '../../../../utils/numbers';

function RoundResults() {
  const { roundResults, gameData } = useConnection();

  const results = useMemo(() => {
    const checkIfLose = (round: { id: number; x: number }) => {
      const last50Results = roundResults.slice(roundResults.length - 50, roundResults.length);
      const findedRound = last50Results.find((result) => result.round_id === round.id);
      if (findedRound) {
        if (findedRound.bet_val < 0) {
          return true;
        }
      }
      return false;
    };
    return gameData?.rounds.map((round) => ({ ...round, isLose: checkIfLose(round) }));
  }, [gameData?.round_id, roundResults]);

  return (
    <Box
      sx={{
        marginTop: '200px',
        marginBottom: '24px',
        maxWidth: '100%',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          minWidth: '100%',
          display: 'flex',
          flexDirection: 'row',
          gap: '8px',
          height: 'max-content',
          overflowX: 'auto',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          '& div:first-of-type': {
            marginLeft: '24px',
          },
          '& div:last-of-type': {
            marginRight: '24px',
          },
        }}
      >
        {results?.map((el, index) => (
          <Box
            key={index}
            sx={{
              borderRadius: '8px',
              background: el.isLose ? 'rgba(241, 65, 53, 0.15)' : 'rgba(132, 190, 57, 0.15)',
              padding: '8px 16px',
              color: el.isLose ? '#F14135' : '#84BE39',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Avenir600',
                fontSize: '14px',
                lineHeight: 'normal',
              }}
            >
              {truncNumber(el.x)}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default RoundResults;
