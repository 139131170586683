import React, { useMemo } from 'react';
import { Box, Button, Modal, Slide, Typography } from '@mui/material';
import { PrizeType, PrizeTypes } from '../../../../utils/constants';
import wheel_reward_tickets from '../../assets/wheel_reward_tickets.webp';
import wheel_reward_ton from '../../assets/wheel_reward_ton.webp';
import wheel_reward_ton_massive from '../../assets/wheel_reward_ton_massive.webp';

function PrizeModal({
  isPrizeModalOpen,
  onClose,
  prize,
}: {
  prize: PrizeType | null;
  isPrizeModalOpen: boolean;
  onClose: () => void;
}) {
  const reward = useMemo(() => {
    switch (prize?.type) {
      case PrizeTypes.MULTI_TON: {
        return {
          title: `${prize.value} TON`,
          bg: wheel_reward_ton_massive,
        };
      }
      case PrizeTypes.TON: {
        return {
          title: `${prize.value} TON`,
          bg: wheel_reward_ton,
        };
      }
      case PrizeTypes.SPIN_TICKETS: {
        return { title: `${prize.value} Spin Tickets`, bg: wheel_reward_tickets };
      }
    }
  }, [prize]);

  return (
    <Modal
      open={isPrizeModalOpen}
      sx={{
        outline: 0,
        '& .MuiBackdrop-root': {
          background: 'rgba(17, 16, 21, 0.9)',
          outline: 0,
        },
      }}
    >
      <Slide direction="up" in={isPrizeModalOpen}>
        <Box
          sx={{
            height: '100%',
            outline: 0,
          }}
        >
          <Box
            sx={{
              background: '#111015',
              backgroundImage: `url(${reward?.bg})`,
              backgroundSize: 'contain',
              maxWidth: '500px',
              position: 'absolute',
              bottom: 0,
              left: '50%',
              transform: 'translateX(-50%)',
              width: '100%',
              borderTopRightRadius: '24px',
              borderTopLeftRadius: '24px',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <Box
              sx={{
                padding: '210px 24px 40px',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Avenir700',
                  fontSize: '40px',
                  lineHeight: 'normal',
                  color: '#fff',
                }}
              >
                {reward?.title}
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Avenir400',
                  fontSize: '16px',
                  lineHeight: 'normal',
                  color: '#FFF',
                  opacity: 0.5,
                  marginTop: '16px',
                  textAlign: 'center',
                }}
              >
                Congratulation! Today luck is on your side. Claim your prize and come back again when you have more Spin
                Tickets
              </Typography>

              <Button
                variant="contained"
                fullWidth
                sx={{
                  fontFamily: 'Avenir600',
                  fontSize: '16px',
                  borderRadius: '12px',
                  background: '#007AFF',
                  height: '54px',
                  textTransform: 'none',
                  marginTop: '32px',
                  '&:hover': {
                    background: '#007AFF',
                  },
                }}
                onClick={onClose}
              >
                Claim prize
              </Button>
            </Box>
          </Box>
        </Box>
      </Slide>
    </Modal>
  );
}

export default PrizeModal;
