import React from 'react';
import { Box, Button, Dialog, Modal, Slide, Typography } from '@mui/material';
import { ReactComponent as Close } from './assets/close.svg';
import { ReactComponent as Infinity } from './assets/infinity.svg';
import { ReactComponent as TonIcon } from './assets/ton_icon.svg';
import { ReactComponent as UpIcon } from './assets/up_icon.svg';
import { useGameState } from '../../../../providers/GameProvider/GameProvider.hooks';
import { AUTOPLAY_DEFAULT_SETTINGS, AUTOPLAY_ROUNDS } from '../../../../utils/constants';
import { useConnection } from '../../../../providers/ConnectionProvider/ConnectionProvider.hooks';
import { useAutoPlay } from '../../../../providers/AutoplayProvider/AutoplayProvider.hooks';

function AutoPlayModal() {
  const {
    isAutoPlayModalOpen,
    setAutoPlayModalOpen,

  } = useGameState();
  const { handleChangeAutoplayOn } = useGameState();
  const { isAutoPlayOn, autoPlaySettings, setAutoPlaySettings } = useAutoPlay();

  return (
    <Dialog
      fullScreen
      open={isAutoPlayModalOpen}
      sx={{
        '& .MuiPaper-root': {
          backgroundColor: 'rgba(17, 16, 21, 0.9)',
        },
      }}
    >
      <Slide direction="up" in={isAutoPlayModalOpen}>
        <Box
          sx={{
            minHeight: '732px',
            overflow: 'auto',
            position: 'absolute',
            top: 0,
            bottom: 0,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
          }}
        >
          <Box
            sx={{
              width: '100%',
              minHeight: '672px',
              overflow: 'auto',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                background: '#393746',
                maxWidth: '500px',
                position: 'relative',
                width: '100%',
                borderTopRightRadius: '24px',
                borderTopLeftRadius: '24px',
                backgroundRepeat: 'no-repeat',
              }}
            >
              <Box
                sx={{
                  borderBottom: '1px solid rgba(103, 101, 119, 0.3)',
                }}
              >
                <Close
                  style={{
                    position: 'absolute',
                    right: '14px',
                    top: '14px',
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    setAutoPlayModalOpen(false);
                  }}
                />
                <Typography
                  sx={{
                    fontFamily: 'Avenir500',
                    fontSize: '16px',
                    lineHeight: 'normal',
                    color: '#fff',
                    textAlign: 'center',
                    padding: '15px 0',
                  }}
                >
                  Auto Play Settings
                </Typography>
              </Box>

              <Box
                sx={{
                  padding: '24px 24px 28px',
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  background: '#24232A',
                }}
              >
                <Box
                  sx={{
                    borderBottom: '1px solid #393746',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingBottom: '24px',
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: 'Avenir600',
                      fontSize: '20px',
                      lineHeight: 'normal',
                      color: '#fff',
                    }}
                  >
                    Auto Play
                  </Typography>
                  <Box
                    sx={{
                      borderRadius: '18px',
                      background: isAutoPlayOn ? '#007AFF' : '#676577',
                      width: '56px',
                      height: '36px',
                      display: 'flex',
                      justifyContent: isAutoPlayOn ? 'flex-end' : 'flex-start',
                    cursor: 'pointer'

                    }}
                    onClick={() => {
                      handleChangeAutoplayOn(!isAutoPlayOn);
                    }}
                  >
                    <Box
                      sx={{
                        width: '28px',
                        height: '28px',
                        borderRadius: '50%',
                        background: 'white',
                        margin: '4px',
                      }}
                    ></Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    transition: 'all 0.3s ease',
                    opacity: isAutoPlayOn ? 1 : 0.5,
                    pointerEvents: isAutoPlayOn ? 'all' : 'none',
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: 'Avenir600',
                      fontSize: '20px',
                      lineHeight: 'normal',
                      color: '#fff',
                      marginTop: '32px',
                      marginBottom: '8px',
                    }}
                  >
                    Rounds
                  </Typography>
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      gap: '8px',
                      ' .tableButton': {
                        fontFamily: 'Avenir600',
                        fontSize: '16px',
                        borderRadius: '12px',
                        backgroundColor: '#393746',
                        height: '46px',
                        textTransform: 'none',
                        padding: 0,
                        width: '100%',
                        boxShadow: 'none',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'white',
                        margin: '2px',
                    cursor: 'pointer'

                      },
                      ' .tableButtonSelected': {
                        fontFamily: 'Avenir600',
                        fontSize: '16px',
                        borderRadius: '12px',
                        outline: '2px solid #007AFF',
                        background: 'rgba(0, 122, 255, 0.20)',
                        height: '46px',
                        textTransform: 'none',
                        padding: 0,
                        width: '100%',
                        boxShadow: 'none',
                        margin: '2px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'white',
                        '&:hover': {
                          background: 'rgba(0, 122, 255, 0.20)',
                        },
                      },
                    }}
                  >
                    {AUTOPLAY_ROUNDS.map((value) => (
                      <Box
                        key={value}
                        className={autoPlaySettings.selectedRounds === value ? 'tableButtonSelected' : 'tableButton'}
                        onClick={() => {
                          setAutoPlaySettings((prev) => ({ ...prev, selectedRounds: value }));
                        }}
                      >
                        {typeof value === 'number' ? value : <Infinity />}
                      </Box>
                    ))}
                  </Box>
                  <Box
                    sx={{
                      height: '1px',
                      width: '100%',
                      background: '#393746',
                      margin: '32px 0',
                    }}
                  ></Box>
                  <Typography
                    sx={{
                      fontFamily: 'Avenir600',
                      fontSize: '20px',
                      lineHeight: 'normal',
                      color: '#fff',
                      marginBottom: '8px',
                      textAlign: 'left',
                      width: '100%',
                    }}
                  >
                    Auto Play rules
                  </Typography>
                  <Box
                    sx={{
                      width: '100%',
                      '.wrapper': {
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingBottom: '8px',
                      },
                      '.title': {
                        fontFamily: 'Avenir500',
                        fontSize: '16px',
                        lineHeight: 'normal',
                        color: '#fff',
                        marginRight: '12px',
                      },
                      '& svg': {
                        position: 'absolute',
                        right: '108px',
                      },
                      '& input': {
                        maxWidth: '70px',
                        minWidth: '70px',
                        height: '46px',
                        borderRadius: '12px',
                        border: '1px solid #393746',
                        background: 'transparent',
                        fontFamily: 'Avenir600',
                        fontSize: '16px',
                        lineHeight: 'normal',
                        color: '#fff',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        paddingLeft: '50px',
                        '&:focus-visible': {
                          outline: 0,
                        },
                      },
                      '& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
                        WebkitAppearance: 'none',
                        margin: 0,
                      },
                      '& input[type=number]': {
                        MozAppearance: 'textfield',
                      },
                    }}
                  >
                    <Box className="wrapper">
                      <Typography className="title">Cash out at</Typography>
                      <input
                        placeholder="1.35x"
                        onClick={() => {
                          setAutoPlaySettings((prev) => ({
                            ...prev,
                            cashOutAt: prev.cashOutAt.toString().replaceAll('x', ''),
                          }));
                        }}
                        onChange={(e) => {
                          if (/^\d*\.?\d*$/.test(e.target.value)) {
                            setAutoPlaySettings((prev) => ({
                              ...prev,
                              cashOutAt: e.target.value,
                            }));
                          }
                        }}
                        onBlur={() => {
                          const value = Number(autoPlaySettings.cashOutAt.toString().replaceAll('x', ''));
                          if (value < 1.35) {
                            setAutoPlaySettings((prev) => ({
                              ...prev,
                              cashOutAt: '',
                            }));
                          } else {
                            setAutoPlaySettings((prev) => ({
                              ...prev,
                              cashOutAt: `${value}x`,
                            }));
                          }
                        }}
                        value={autoPlaySettings.cashOutAt}
                      ></input>
                      <UpIcon />
                    </Box>

                    <Box className="wrapper">
                      <Typography className="title">Stop if win more than</Typography>
                      <input
                        placeholder="0"
                        onChange={(e) => {
                          if (/^\d*\.?\d*$/.test(e.target.value)) {
                            setAutoPlaySettings((prev) => ({
                              ...prev,
                              stopIfWinMoreThan: e.target.value,
                            }));
                          }
                        }}
                        value={autoPlaySettings.stopIfWinMoreThan}
                        onBlur={() => {
                          setAutoPlaySettings((prev) => {
                            const value = Number(prev.stopIfWinMoreThan) > 0 ? Number(prev.stopIfWinMoreThan) : '';
                            return {
                              ...prev,
                              stopIfWinMoreThan: value,
                            };
                          });
                        }}
                      ></input>
                      <TonIcon />
                    </Box>

                    <Box className="wrapper">
                      <Typography className="title">Stop if loose more than</Typography>
                      <input
                        placeholder="0"
                        onChange={(e) => {
                          if (/^\d*\.?\d*$/.test(e.target.value)) {
                            setAutoPlaySettings((prev) => ({
                              ...prev,
                              stopIfLooseMoreThan: e.target.value,
                            }));
                          }
                        }}
                        value={autoPlaySettings.stopIfLooseMoreThan}
                        onBlur={() => {
                          setAutoPlaySettings((prev) => {
                            const value = Number(prev.stopIfLooseMoreThan) > 0 ? Number(prev.stopIfLooseMoreThan) : '';

                            return {
                              ...prev,
                              stopIfLooseMoreThan: value,
                            };
                          });
                        }}
                      ></input>
                      <TonIcon />
                    </Box>

                    <Box className="wrapper">
                      <Typography className="title">Stop if single win exceeds</Typography>
                      <input
                        placeholder="0"
                        onChange={(e) => {
                          if (/^\d*\.?\d*$/.test(e.target.value)) {
                            setAutoPlaySettings((prev) => ({
                              ...prev,
                              stopIfSingleWinExceeds: e.target.value,
                            }));
                          }
                        }}
                        value={autoPlaySettings.stopIfSingleWinExceeds}
                        onBlur={() => {
                          setAutoPlaySettings((prev) => {
                            const value =
                              Number(prev.stopIfSingleWinExceeds) > 0 ? Number(prev.stopIfSingleWinExceeds) : '';
                            return {
                              ...prev,
                              stopIfSingleWinExceeds: value,
                            };
                          });
                        }}
                      ></input>
                      <TonIcon />
                    </Box>
                  </Box>

                  <Button
                    variant="contained"
                    fullWidth
                    sx={{
                      fontFamily: 'Avenir600',
                      fontSize: '16px',
                      borderRadius: '12px',
                      background: '#393746',
                      height: '54px',
                      textTransform: 'none',
                      marginTop: '24px',
                      boxShadow: 'none',
                      '&:hover': {
                        background: '#393746',
                      },
                    }}
                    onClick={() => {
                      setAutoPlaySettings(AUTOPLAY_DEFAULT_SETTINGS);
                    }}
                  >
                    Reset settings
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Slide>
    </Dialog>
  );
}

export default AutoPlayModal;
