import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { useTelegram } from '../../providers/TelegramProvider/TelegramProvider';
import { useGameState } from '../../providers/GameProvider/GameProvider.hooks';
import { APP_VIEWS } from '../../utils/constants';

function TermsOfUse() {
  const { setAppView } = useGameState();
  const { webApp } = useTelegram();
  useEffect(() => {
    if (webApp) {
      webApp.BackButton.onClick(() => {
        webApp.BackButton.hide();
        setAppView(APP_VIEWS.MAIN);
      });
      webApp.BackButton.show();
    }
  }, [webApp, setAppView]);

  return (
    <Box
      sx={{
        maxWidth: '700px',
        margin: 'auto',
        overflow: 'hidden',
        position: 'relative',
        backgroundColor: 'white',
        padding: '20px',
      }}
    >
      <div className="container">
        <h2>Terms Of Use Agreement for Jetlix Web Application</h2>
        <h4>Date: February 21, 2024</h4>
        <div className="text">
          The <strong>Jetlix</strong> Web Application (collectively, “Service”, “App’) is a website and application
          owned and operated by the ROSIANE DA ROSA ANDRADES, a citizen of the Brazil, whose residence address is at Rua
          Nove 1424, Jaboatão Dos Guararapes, Pernambuco 54240-080, Brazil (hereinafter “we”, “us”, “ROSIANE DA ROSA
          ANDRADES” etc.).
        </div>
        <div className="text">
          Jetlix: Game of Pharaohs is a non-gambling game designed for entertainment purposes only. No real money is
          involved, and the game is crafted to provide a delightful experience centered around the captivating world of
          ancient Egypt. The in-game currency used is called ‘Points’ and cannot be exchanged for cash or be paid out in
          any form. ‘Points’ can only be used to play these games. The games are intended solely for an adult audience
          which is 18+ years.
        </div>
        <div className="text">
          This Terms of Use Agreement (this “Agreement”) describes the terms according to which you may legally use the
          Service, and the content contained there.
        </div>
        <div className="text">
          By your use of this App, you agree to be bound by the terms and conditions of this Agreement, including any
          policies and procedures incorporated by reference, including these Terms of Use Agreement and our
          <a href="privacy.html">Privacy Policy</a>.
        </div>
        <div className="text">
          You also represent and warrant that you have the legal authority to accept the Agreement. If you do not agree
          to all of the terms in this Agreement, you are not authorized to use or access the Service.
        </div>
        <div className="text">
          In order to understand the rules of the Agreement clearly you shall familiarize yourself with the meaning of
          terms used in the Agreement. In this Agreement the following terms, when capitalized, shall have the following
          meanings:
          <ul>
            <li>
              <strong>“Account”</strong> – means a personal account of a user of the Services.
            </li>
            <li>
              <strong>“Application”, “App”, “Service”</strong> – means an application for a mobile device and web
              application that We make available on our Website, as well as any services provided through the
              Application.
            </li>
            <li>
              <strong>“We”</strong> – means ROSIANE DA ROSA ANDRADES, a citizen of the Viet Nam, whose residence address
              is at Rua Nove 1424, Jaboatão Dos Guararapes, Pernambuco 54240-080, Brazil.
            </li>
            <li>
              <strong>“Content”</strong> – means all kinds of content, including, among other, data, texts, images,
              videos, sounds, links, software and other pieces of information in any form, which are made available to
              You through the App.
            </li>
            <li>
              <strong>“Device”</strong> – means a smartphone, a tablet or another similar mobile device as well as a
              computer that You either own or otherwise legally control for Your personal non-commercial use.
            </li>
            <li>
              <strong>“Intellectual Property Rights”</strong> – means, collectively, rights under any patent, trademark,
              copyright and trade secret laws, and any other intellectual property or proprietary rights recognized in
              any country or jurisdiction worldwide, including, without limitation, moral or similar rights.
            </li>
            <li>
              <strong>“Website”</strong> – means https://jetlix.com/.
            </li>
            <li>
              <strong>“You”</strong> – means an individual using the App.
            </li>
          </ul>
        </div>
        <h3>DISCLAIMER OF WARRANTIES</h3>
        <div className="text bold">
          Please read the following disclaimer carefully before engaging in any activities on our online casino
          platform:
          <ol>
            <li>
              Entertainment Purposes Only: The games offered on this online casino are intended for entertainment
              purposes only. They are simulations and do not involve the use of real money.
            </li>
            <li>
              No Real Money Wagers: Players are reminded that no real money wagers are permitted on this platform. All
              bets and winnings are purely virtual and for amusement purposes and cannot be exchanged for real money or
              prizes.
            </li>
          </ol>
        </div>
        <div className="text">
          By accessing and using this online casino, you acknowledge that you have read and understood this disclaimer.
          Any participation in the games offered is at your own risk, and the online casino holds no liability for any
          outcomes.
        </div>
        <div className="text">
          To the fullest extent permitted by applicable law, We and Our affiliates, officers, employees, agents,
          partners, and licensors expressly disclaim all warranties of any kind, express or implied, as to the accuracy,
          adequacy or completeness of any of the information contained in Our Service or created by the services.
        </div>
        <div className="text">
          The Content in Our Service is provided to You on an “as is” basis, without any warranties as to
          merchantability or fitness for a particular purpose or use. We do not guarantee that You will attain any
          anticipated result from the use of the Service. Further, We do not warrant or make any representations
          concerning the accuracy, likely results, or reliability of the use of the materials on its Services or
          otherwise relating to such materials or on any sites linked to its Services.
        </div>
        <div className="text">
          We make no warranty that:
          <ol type="a">
            <li>the Service will meet your requirements;</li>
            <li>the Service will be uninterrupted, timely, secure, or error-free;</li>
            <li>content and material will be hosted and/or transmitted without interruption or cessation;</li>
            <li>
              any products, sites, information, or other material, whether in tangible or intangible form, downloaded or
              obtained by you through the service, will meet your expectations or any standard of quality; and
            </li>
            <li>any defects in the operation or functionality of the service or related software will be corrected.</li>
          </ol>
        </div>
        <div className="text">
          Any service downloaded or otherwise accessed through the use of Our Service is at Your own discretion and risk
          and We shall have no responsibility or liability for any errors or omissions.
        </div>
        <div className="text">
          We are not responsible for any damages, whether direct or indirect, special or consequential, suffered by You
          or by any other person from the use of the Service, even if You have been advised of the possibility of such
          damages.
        </div>
        <div className="text">
          No advice or information, whether oral or written, obtained by You from Us or Our Service shall create any
          warranty not expressly stated in the Agreement. Under no circumstances shall Our liability or of any of Our
          affiliates pursuant to any cause of action, whether in contract, tort, or otherwise, exceed the fee paid by
          You for access to the Service. Furthermore, We shall have no responsibility or liability for delays or
          failures due to circumstances beyond Our control.
        </div>

        <h3>CHANGES AND MODIFICATIONS</h3>
        <div className="text">
          We may change or amend this Agreement from time to time. If we make material changes, we will endeavor to
          notify you that our terms have changed, either through the user interface, in an email message, or through
          other reasonable means. Your use of the Service after the date such change(s) become effective will constitute
          your consent to the changed terms.
        </div>
        <div className="text">
          If you do not agree to the change(s), you must immediately stop using the Service; otherwise, the new terms
          will apply to you.
        </div>
        <div className="text">
          As long as you comply with this Agreement, we grant you a limited, revocable, non-exclusive, non-assignable,
          non-sublicensable right to access and use the Service as it is intended to be accessed and used, and in
          accordance with this Agreement and applicable law. We grant you no other rights, implied or otherwise.
        </div>
        <h3>LEGAL CAPACITY AND AGE</h3>
        <div className="text">
          The Service is intended for adults only. You must be 18 years of age or older to use the Service. If You
          install and/or use the Service, You confirm that You are at least 18 years old and have full legal capacity to
          enter into the Agreement.
        </div>
        <h3>DEVICE</h3>
        <div className="text">
          If You intend to download onto and/or run the Service on any Device not owned by You, You must have the
          owner’s permission to do so. You will be responsible for complying with this Agreement whether or not You own
          the Device.
        </div>
        <div className="text">
          If You do not own the Device, You are allowed to install and/or use the App only through Your Account.
        </div>
        <div className="text">
          If You use the Device together with other people, please, make sure that nobody except You can use the Service
          from Your Account. All actions taken in Your Account are considered Your actions in disregard of who actually
          takes such actions. You are fully responsible for all actions taken in Your Account.
        </div>
        <div className="text">
          If You decide to sell or otherwise dispose of the Device, You shall delete the Application from the Device.
        </div>
        <h3>ACCOUNT</h3>
        <div className="text">
          In order to access some features of Our Services‚ you may be required to register and We may assign to you, or
          you may be required to select, a password and user name or account identification. If you register‚ you agree
          to provide Us with accurate and complete registration information‚ and to inform Us immediately of any updates
          or other changes to such information.
        </div>
        <div className="text">
          You are solely responsible for protecting the security and confidentiality of the password and identification
          assigned to you. You shall immediately notify Us of any unauthorized use of your password or identification or
          any other breach or threatened breach of this Site's security. Each time you use a password or identification,
          you will be deemed to be authorized to access and use Our Services in a manner consistent with these Terms,
          and We have no obligation to investigate the authorization or source of any such access or use of this Site.
        </div>
        <div className="text">
          YOU WILL BE SOLELY RESPONSIBLE FOR ALL ACCESS TO AND USE OF OUR SERVICES BY ANYONE USING THE PASSWORD AND
          IDENTIFICATION ORIGINALLY SELECTED BY, OR ASSIGNED TO, YOU WHETHER OR NOT SUCH ACCESS TO AND USE OF OUR
          SERVICES IS ACTUALLY AUTHORIZED BY YOU, INCLUDING WITHOUT LIMITATION, ALL COMMUNICATIONS AND TRANSMISSIONS AND
          ALL OBLIGATIONS (INCLUDING WITHOUT LIMITATION FINANCIAL OBLIGATIONS) INCURRED THROUGH SUCH ACCESS OR USE.
        </div>
        <h3>INTELLECTUAL PROPERTY</h3>
        <div className="text">
          All text, metadata, user interfaces and visual interfaces (including graphics, photographs, sounds, music, and
          artwork), trademarks, logos, computer code, and any and all compilations thereof (collectively, “Our
          Content”), including but not limited to the design, structure, selection, coordination, expression, “look and
          feel,” and arrangement of Our Content, is owned, controlled, or licensed by or to Us, and is protected by
          trade dress, copyright, and trademark laws, and is subject to various other intellectual property rights.
        </div>
        <div className="text">
          We hereby grant You a non-exclusive, personal, limited, revocable and non-transferable license to access,
          download and run the Service only on Your Device, provided that You agree to comply with all the terms and
          conditions of this Agreement. Please, take into consideration that the Service is licensed, not sold, to You.
        </div>
        <div className="text">
          We or our licensor is the only holder of Intellectual Property Rights with regard to the Service. We retains
          all rights, title and interest in and to the Service and reserves all rights not expressly granted to You in
          this Agreement.
        </div>
        <div className="text">
          You may not sell, resell, distribute, redistribute, rent, lease, sublicense, assign, copy, or in any other way
          transfer or grant any rights to the Service to any third party.
        </div>
        <div className="text">
          You may not distribute or make the Service available over any network where it could be downloaded or used by
          multiple users at the same time, except when it is expressly authorized by Us.
        </div>
        <div className="text">
          You may not use any automatic or manual device or process to interfere with the proper working of the Service.
          However, You may use any kind of device or process to delete the Service from Your Device.
        </div>
        <div className="text">
          You may not decompile, disassemble, reverse engineer or create derivative works of the Service or any parts
          thereof. All modifications or enhancements to the Service remain the sole property of the Company.
        </div>
        <div className="text">
          You may not decompile, disassemble, reverse engineer or create derivative works of the Service or any parts
          thereof. All modifications or enhancements to the Service remain the sole property of the Company.
        </div>
        <h3>USES OF THE SERVICE</h3>
        <div className="text">
          You agree not to, and will not assist, encourage, or enable others to:
          <ul>
            <li>You agree not to, and will not assist, encourage, or enable others to:</li>
            <li>Reverse engineer any portion of the Service.</li>
            <li>
              Use any robot, spider, site search/retrieval application, or other automated device, process, or means not
              provided by Us to access, retrieve, scrape, or index any portion of Service
            </li>
            <li>Record, process, or mine information about other users or about Service.</li>
            <li>
              Take any action that imposes, or may impose, in our sole discretion, an unreasonable or disproportionately
              large load on our technology infrastructure or otherwise make excessive traffic demands on Service
            </li>
            <li>
              Interfere with the proper working of Service, circumvent or otherwise interfere with any security-related
              features of Service, or attempt to gain unauthorized access to Service, registered user accounts, or any
              computers/networks associated with Service through hacking, password mining, or any other means.
            </li>
            <li>
              Use the Service for any illegal or unauthorized purpose. You agree to comply with all applicable laws,
              including the laws of your local jurisdiction, regarding online conduct and acceptable content (including
              but not limited to intellectual property laws).
            </li>
          </ul>
        </div>
        <h3>RELATED SERVICES AND ADVICE</h3>
        <div className="text">
          Please, take into account that any kind of data provided to You through the App is provided for information
          purposes only. It is up to You whether to use this information as the basis for Your activities and decisions.
          Under no circumstances shall We be responsible for any consequences of using such information as the basis for
          Your activities and decisions.
        </div>
        <div className="text">
          Jetlix's features can change in the future, including adding and removing features, restricting the list of
          features available in the free/paid plan, open source some parts or all of the app source code.
        </div>
        <h3>LINKS TO EXTERNAL SITES AND SERVICES</h3>
        <div className="text">
          The App may contain links to external sites and services (for example, applications for mobile devices). If
          You decide to visit and/or use such external sites and services, You do this at Your own risk. Under no
          circumstances shall We be responsible for the content of such sites and services. We cannot guarantee that
          these sites and services will be available for You or that Your use of such sites and services will be safe.
        </div>
        <div className="text">
          This Agreement does not regulate Your relations with owners and/or administrators of such external sites and
          services. Please, check in terms of service, privacy policy or any other related document that regulates usage
          of external sites and services.
        </div>
        <h3>UPDATES</h3>
        <div className="text">
          We may, though not obliged, update the App from time to time, making such updates, supplements or new versions
          of the App available to You. If We release an update (including a new version of the App) We may request You
          to update the App. The update may be downloaded and installed automatically or on the basis of Your consent
          depending on the updates settings of Your Device.
        </div>
        <div className="text">
          The materials appearing on ROSIANE DA ROSA ANDRADES's Services could include technical, typographical, or
          photographic errors. ROSIANE DA ROSA ANDRADES does not warrant that any of the materials on its Services are
          accurate, complete, or current. ROSIANE DA ROSA ANDRADES may make changes to the materials contained on its
          Services at any time without notice. ROSIANE DA ROSA ANDRADES does not, however, make any commitment to update
          the materials
        </div>
        <div className="text">
          The content of any update, supplement or new version of the App is determined exclusively by the Company.
          Under no circumstances do We accept any orders, requires or requests for further development of the App. We
          reserve the right to modify or discontinue any part, or function, or feature of the App at Our own discretion
          with or without a prior notice.
        </div>
        <div className="text">
          If We introduce certain features or functions of the App, We may need to set certain rules for using such
          features or functions. In this case We will introduce changes to the Terms of Use that will take effect on the
          day when such features or functions are released.
        </div>
        <div className="text">
          We are committed to making Service usable and accessible to the widest possible audience, regardless of
          technology or ability. While We strive to ensure the accessibility of Service, you may find some limitations.
          Please report any problems to the support team at Service (
          <a href="mailto:support%40jetlix.com">support@jetlix.com</a>).
        </div>
        <h3>VIOLATION OF THIS AGREEMENT – TERMINATION</h3>
        <div className="text">
          We may end Your rights to use the Service at any time by contacting You if You have broken the rules of these
          Agreement in a serious way. If what You have done can be put right We will give You a reasonable opportunity
          to do so.
        </div>
        <div className="text">
          If We end Your rights to use the Service:
          <ul>
            <li>You must stop all activities authorized by these Agreement, including Your use of the Service;</li>
            <li>
              You must delete or remove the Service from all Devices and immediately destroy all copies of the Service
              which You have and confirm to Us that You have done this.
            </li>
          </ul>
        </div>
        <h3>LIMITATION OF LIABILITY</h3>
        <div className="text">
          <ul>
            <li>
              You expressly understand and agree that we and our affiliates will not be liable to you for any indirect,
              incidental, special, consequential, exemplary, or punitive damages that may be incurred by you, however
              caused and under any theory of liability.
            </li>
            <li>
              This includes, but is not limited to, any loss of profits, goodwill, or business reputation; any loss of
              data; any cost of procurement of substitute goods or services; or any other intangible losses. This also
              includes any loss or damages that may be incurred by you as a result of (a) any changes that we may make
              to the service ; (b) any permanent or temporary cessation of the service ; (c) the deletion or corruption
              of, or failure to store, any content or data (including without limitation user content, educational
              material, and metadata) maintained through the service ; or (d) your failure to keep your registered user
              account details secure.
            </li>
            <li>
              The limitations and exclusions of liability above shall apply irrespective of the theory of liability,
              including contract (including fundamental breach), warranty, product liability, strict liability, tort
              (including negligence), or other theory, even if we (or our affiliates) have been advised of the
              possibility of such damages.
            </li>
          </ul>
        </div>
        <div className="text">
          <strong>Exclusions and Limitations.</strong> Some jurisdictions do not permit the exclusion of certain
          warranties or conditions, or the limitation or exclusion of liability for certain damages. Accordingly, only
          the limitations that are lawful in your jurisdiction will apply to you and, in such instances, our liability
          and that of our affiliates will be limited to the maximum extent permitted by law. You agree that ROSIANE DA
          ROSA ANDRADES's liability is limited to the amount actually paid by you for your services or software, which
          amount calculated in reliance upon this clause. You hereby release ROSIANE DA ROSA ANDRADES from any and all
          obligations, liabilities and claims in excess of this limitation.
        </div>
        <div className="text">
          <strong>Indemnification.</strong> You agree to defend, indemnify, and hold Us and Our Affiliates harmless from
          and against any and all liabilities and costs (including reasonable attorneys’ fees) we may incur in
          connection with any claim arising out of your breach of this Agreement or your use of the Service. We reserve
          the right, but have no obligation, to assume the exclusive defense and control of any matter otherwise subject
          to indemnification by you.
        </div>
        <h3>TRANSFER OF RIGHTS</h3>
        <div className="text">
          We may transfer Our rights and obligations under these Terms of Use to any other organization or individual
          without Your consent. We will ensure that the transfer will not affect Your rights under the Terms of Use.
        </div>
        <div className="text">
          You may only transfer Your rights or Your obligations under these Terms of Use to another person if We agree
          in writing.
        </div>
        <h3>YOUR VIOLATION OF THE TERMS OF USE</h3>
        <div className="text">
          We may end Your rights to use the App at any time by contacting You if You have broken the rules of these
          Terms of Use in a serious way. If what You have done can be put right We will give You a reasonable
          opportunity to do so.
        </div>
        <div className="text">If We end Your rights to use the App:</div>
        <div className="text">
          You must stop all activities authorized by these Terms of Use, including Your use of the App;
        </div>
        <div className="text">
          You must delete or remove the App from all Devices and immediately destroy all copies of the App which You
          have and confirm to Us that You have done this.
        </div>
        <h3>MISCELLANEOUS</h3>
        <ol>
          <li>
            <strong>Entire Agreement.</strong> These Terms of Use contain the entire agreement between you and Look of
            Ray regarding the use of the Service, and supersede any prior agreement between you and Jetlix on such
            subject matter. The parties acknowledge that no reliance is placed on any representation made but not
            expressly contained in these Terms of Use. You may also be subject to additional terms and conditions that
            may apply when you use or purchase other goods or services from us, our affiliates, or our parents or
            subsidiary entities.
          </li>
          <li>
            <div>
              <strong>Governing Law.</strong> If any dispute, controversy or difference arises between You and the
              Company, it shall be settled amicably through negotiations. In case such dispute, controversy or
              difference cannot be settled amicably through negotiations within a thirty 30-day period it shall be
              settled by the Vietnam International Arbitration Centre in accordance with its International Expedited
              Procedures.
            </div>
            <div>The number of arbitrators shall be one.</div>
            <div>The place of arbitration shall be Ho Chi Minh City, Vietnam.</div>
            <div>The arbitration shall be held, and the award rendered, in English.</div>
            <div>
              The appointing authority shall be the Vietnam International Arbitration Centre acting in accordance with
              the rules adopted by the Vietnam International Arbitration Centre for this purpose. Each party (i.e. You
              and the Company) shall bear its own expenses, but the parties shall share equally the expenses of the
              arbitration tribunal.
            </div>
          </li>
          <li>
            <strong>Statute Of Limitations.</strong> You agree that, regardless of any statute or law to the contrary,
            any claim under this Agreement must be brought within one (1) year after the cause of action arises, or such
            claim or cause of action is forever barred.
          </li>
          <li>
            <strong>Severability of Terms.</strong> Each of the paragraphs of these Terms of Use operates separately. If
            any court or relevant authority decides that any of them is unlawful, the remaining paragraphs will remain
            in full force and effect.
          </li>
          <li>
            <strong>Notices.</strong> We may provide you with notices, including those regarding changes to the
            Agreement, by email, by regular mail, or by postings to Service If you have general questions or concerns
            regarding these Terms of Use, please contact us in writing at{' '}
            <a href="mailto:support%40jetlix.com">support@jetlix.com</a>.
          </li>
        </ol>
      </div>
    </Box>
  );
}

export default TermsOfUse;
