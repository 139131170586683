import { Box, Typography } from '@mui/material';
import { useConnection } from '../../../providers/ConnectionProvider/ConnectionProvider.hooks';
import { APP_NETWORKS, APP_VIEWS } from '../../../utils/constants';
import { ReactComponent as TonSvg } from './assets/ton_huge_black.svg';
import decor from './assets/decor.svg';
import { useGameState } from '../../../providers/GameProvider/GameProvider.hooks';

const version = 0.063;

function Footer({ withoutHtP = false }: { withoutHtP?: boolean }) {
  const { userNetwork } = useConnection();
  const { tonExchangeRate, setAppView } = useGameState();

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <Box
        sx={{
          height: '40px',
          backgroundImage: `url(${decor})`,
          backgroundSize: 'contain',
          backgroundColor: '#24232A',
          backgroundRepeat: 'repeat-x',
        }}
      ></Box>
      <Box
        sx={{
          background: '#24232A',
          padding: '24px 24px 32px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <TonSvg />
        <Typography
          sx={{
            fontFamily: 'Avenir600',
            fontSize: '16px',
            lineHeight: 'normal',
            color: '#676577',
            marginTop: '16px',
          }}
        >
          1 TON = {tonExchangeRate} USD
        </Typography>
        <Box
          sx={{
            width: '100%',
            height: '1px',
            background: '#393746',
            margin: '24px 0',
          }}
        ></Box>

        <Typography
          sx={{
            fontFamily: 'Avenir400',
            fontSize: '14px',
            lineHeight: 'normal',
            color: '#676577',
            marginTop: '16px',
          }}
        >
          All rights reserved. Jetlix 2024
        </Typography>
        <Box
          sx={{
            display: 'flex',
            gap: '5px',
            marginTop: '8px',
            '& p': {
              fontFamily: 'Avenir600',
              fontSize: '14px',
              lineHeight: 'normal',
              color: '#007AFF',
              cursor: 'pointer',
            },
            '.dot': {
              color: '#676577',
            },
          }}
        >
          {!withoutHtP && (
            <>
              <Typography
                onClick={() => {
                  setAppView(APP_VIEWS.HOW_TO_PLAY_JETLIX);
                }}
              >
                How to Play
              </Typography>
              <Typography className="dot">·</Typography>
            </>
          )}
          <Typography
            onClick={() => {
              setAppView(APP_VIEWS.PRIVACY_POLICY);
            }}
          >
            Privacy Policy
          </Typography>
          <Typography className="dot">·</Typography>
          <Typography
            onClick={() => {
              setAppView(APP_VIEWS.TERMS_OF_USE);
            }}
          >
            Terms of Use
          </Typography>
        </Box>

        <Typography
          sx={{
            fontFamily: 'Avenir600',
            fontSize: '12px',
            lineHeight: 'normal',
            color: '#fff',
            marginRight: '10px',
            position: 'absolute',
            bottom: 5,
            right: 5,
            opacity: 0.2,
          }}
        >
          {userNetwork === APP_NETWORKS.MAINNET ? 'Mainnet' : 'Testnet'} {version}
        </Typography>
      </Box>
    </Box>
  );
}

export default Footer;
