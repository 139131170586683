import React, { useEffect, useMemo } from 'react';
import { Box, Typography } from '@mui/material';

import { useGameState } from '../../providers/GameProvider/GameProvider.hooks';
import { useTelegram } from '../../providers/TelegramProvider/TelegramProvider';
import { APP_VIEWS, HOW_TO_PLAY_MODES } from '../../utils/constants';
import { ReactComponent as Icon } from './assets/icon.svg';
import background from './assets/manual_background_image.webp';
import manual_image_1 from './assets/manual_image_1.webp';
import manual_image_2 from './assets/manual_image_2.webp';
import manual_image_3 from './assets/manual_image_3.webp';
import manual_image_4 from './assets/manual_image_4.webp';
import manual_image_5 from './assets/manual_image_5.webp';
import manual_image_6 from './assets/manual_image_6.webp';
import Footer from '../common/Footer';

function HowToPlay({ mode }: { mode: keyof typeof HOW_TO_PLAY_MODES }) {
  const { setAppView } = useGameState();
  const { webApp } = useTelegram();

  useEffect(() => {
    if (webApp) {
      switch (mode) {
        case HOW_TO_PLAY_MODES.JETLIX: {
          webApp.BackButton.onClick(() => {
            webApp.BackButton.hide();
            setAppView(APP_VIEWS.MAIN);
          });
          webApp.BackButton.show();
          break;
        }
        case HOW_TO_PLAY_MODES.FORTUNE_WHEEL: {
          webApp.BackButton.onClick(() => {
            webApp.BackButton.hide();
            setAppView(APP_VIEWS.FORTUNE_WHEEL);
          });
          webApp.BackButton.show();
        }
      }
    }
  }, [webApp, setAppView, mode]);

  const data = useMemo(() => {
    switch (mode) {
      case HOW_TO_PLAY_MODES.JETLIX: {
        return {
          title: 'How to play Jetlix',
          subTitle: 'Flight longer then opponents and win the bank!',
          data: [
            {
              title: 'Make your bet before takeoff',
              subTitle:
                'Each customized plane receives 1 exp point for each €1.00 (1 EUR, $1.09) bet. We will use currency conversion rates for other currencies.',
              image: manual_image_1,
            },
            {
              title: 'Try your luck and wait for the odds to go higher',
              subTitle:
                'After attaining a higher level you will be given the opportunity to add an additional feature to your plane thus adding aesthetic value and uniqueness.',
              image: manual_image_2,
            },
            {
              title: 'Land your plane before it explodes',
              subTitle: 'Make it manually or use autoplay mode to avoid explosion in the sky',
              image: manual_image_3,
            },
            {
              title: 'Auto bet',
              subTitle:
                'You can make your bets automatically by turning the «Auto» switch on. To cash out automatically, specify the exact odds desired in the field beside the indicated amount.',
              image: manual_image_4,
            },
          ],
        };
      }
      case HOW_TO_PLAY_MODES.FORTUNE_WHEEL: {
        return {
          title: 'How to play Fortune Wheel',
          subTitle: 'Spin and win TON cryptocurrency, Free Bets and Spin Tickets!',
          data: [
            {
              title: 'How to win?',
              subTitle: 'To win a prize, simply spin the wheel. For one spin you need 1 ticket.',
              image: manual_image_5,
            },
            {
              title: 'How to get tickets?',
              subTitle: 'To win a prize, simply spin the wheel. For one spin you need 1 ticket.',
              image: manual_image_6,
            },
          ],
        };
      }
    }
  }, [mode]);

  return (
    <Box
      sx={{
        maxWidth: '700px',
        margin: 'auto',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          backgroundImage: `url(${background})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          minHeight: '1000px',
          padding: '40px 24px 48px',
        }}
      >
        <Icon />
        <Typography
          sx={{
            fontFamily: 'Avenir600',
            fontSize: '20px',
            lineHeight: 'normal',
            color: '#fff',
            textAlign: 'center',
            marginTop: '24px',
          }}
        >
          {data?.title}
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Avenir400',
            fontSize: '16px',
            lineHeight: 'normal',
            color: '#fff',
            opacity: 0.5,
            textAlign: 'center',
            marginTop: '4px',
          }}
        >
          {data?.subTitle}
        </Typography>
        {data?.data.map((el, index) => (
          <Box
            key={index}
            sx={{
              width: '100%',
            }}
          >
            <Box
              sx={{
                width: '100%',
                height: '1px',
                background: '#24232A',
                margin: '40px 0',
              }}
            ></Box>
            <Typography
              sx={{
                fontFamily: 'Avenir600',
                fontSize: '20px',
                lineHeight: 'normal',
                color: '#fff',
                marginTop: '24px',
              }}
            >
              {el.title}
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Avenir400',
                fontSize: '16px',
                lineHeight: 'normal',
                color: '#fff',
                opacity: 0.5,
                marginTop: '8px',
              }}
            >
              {el.subTitle}
            </Typography>
            <Box
              sx={{
                marginTop: '24px',
                '& img': {
                  maxWidth: '100%',
                },
              }}
            >
              <img src={el.image} alt="faq"></img>
            </Box>
          </Box>
        ))}
      </Box>
      <Footer withoutHtP />
    </Box>
  );
}

export default HowToPlay;
