import { GameDataType } from '../providers/ConnectionProvider/ConnectionProvider.types';
import { AutoPlaySettingsType, AutoPlayValuesType } from './constants';

export function checkIsNeedToStop(
  autoPlaySettings: AutoPlaySettingsType,
  autoPlayData: AutoPlayValuesType,
  gameData: GameDataType
) {
  const isNeedToStopByWinMoreThan =
    autoPlaySettings.stopIfWinMoreThan !== ''
      ? autoPlayData?.winAmount > Number(autoPlaySettings.stopIfWinMoreThan)
      : false;
  // console.log('isNeedToStopByWinMoreThan', isNeedToStopByWinMoreThan);
  const isNeedToStopByLooseMoreThan =
    autoPlaySettings.stopIfLooseMoreThan !== ''
      ? autoPlayData?.loseAmount > Number(autoPlaySettings.stopIfLooseMoreThan)
      : false;
  // console.log('isNeedToStopByLooseMoreThan', isNeedToStopByLooseMoreThan);

  const isNeedToStopBySingleWin =
    autoPlaySettings.stopIfSingleWinExceeds !== ''
      ? autoPlayData?.maxWin > Number(autoPlaySettings.stopIfSingleWinExceeds)
      : false;
  // console.log('isNeedToStopBySingleWin', isNeedToStopBySingleWin);

  const isNeedToStopByRounds =
    autoPlaySettings.selectedRounds !== 'infinite'
      ? autoPlayData?.startRound + autoPlaySettings.selectedRounds <= gameData.round_id
      : false;
  // console.log('isNeedToStopByRounds', isNeedToStopByRounds);

  const isNeedToStop =
    isNeedToStopByWinMoreThan || isNeedToStopByLooseMoreThan || isNeedToStopBySingleWin || isNeedToStopByRounds;

  return isNeedToStop;
}
