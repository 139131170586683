import React from 'react';
import { Box, Typography } from '@mui/material';

function Error() {
  return (
    <Box
      sx={{
        background: '#111015',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        position: 'absolute',
      }}
    >
      <Typography
        sx={{
          fontFamily: 'Avenir700',
          fontSize: '40px',
          lineHeight: 'normal',
          color: '#fff',
          margin: '24px',
          textAlign: 'center',
        }}
      >
        Please use <a href=" https://t.me/jetlix_bot">Jetlix</a> to open this page
      </Typography>
    </Box>
  );
}

export default Error;
